import { ConfigModel } from '@ibe/services';
import { ExternalParamsContextProps } from './ExternalParamsContext';

interface LooseObject {
  [key: string]: unknown;
}

const isointtest: LooseObject = {
  LLD: {
    configUrl: 'https://megibe-lld-test.ops.local/config.json',
    apiUrl: 'https://megibe-lld-test.ops.local/api',
    translationsUrl: 'https://megibe-lld-test.ops.local/',
    appTitle: 'Continuous / MEG IBE',
    googleTagManagerContainerId: 'GTM-K9TZSMB',
    traveler: {
      mockAvailable: false
    }
  },
  LLF: {
    configUrl: 'https://megibe-llf-test.ops.local/config.json',
    apiUrl: 'https://megibe-llf-test.ops.local/api',
    translationsUrl: 'https://megibe-llf-test.ops.local/',
    appTitle: 'Continuous / MEG IBE',
    googleTagManagerContainerId: 'GTM-K9TZSMB',
    traveler: {
      mockAvailable: false
    }
  },
  LNY: {
    configUrl: 'https://megibe-lny-test.ops.local/config.json',
    apiUrl: 'https://megibe-lny-test.ops.local/api',
    translationsUrl: 'https://megibe-lny-test.ops.local/',
    appTitle: 'Continuous / MEG IBE',
    googleTagManagerContainerId: 'GTM-K9TZSMB',
    traveler: {
      mockAvailable: false
    }
  },
  GLH: {
    configUrl: 'https://megibe-llb.isotravel.com/config.json',
    apiUrl: 'https://megibe-llb.isotravel.com/api',
    translationsUrl: 'https://megibe-llb.isotravel.com/',
    appTitle: 'Continuous / MEG IBE',
    googleTagManagerContainerId: 'GTM-K9TZSMB',
    traveler: {
      mockAvailable: false
    }
  }
};

const isointprod: LooseObject = {
  LLD: {
    configUrl: 'https://newibe-cont.ibe-meg.isoad.isogmbh.de/config.json',
    apiUrl: 'https://newibe-cont.ibe-meg.isoad.isogmbh.de/api',
    translationsUrl: 'https://newibe-cont.ibe-meg.isoad.isogmbh.de/',
    appTitle: 'Continuous / MEG IBE',
    googleTagManagerContainerId: 'GTM-K9TZSMB',
    traveler: {
      mockAvailable: false
    }
  }
};

const test: LooseObject = {
  LLD: {
    configUrl: 'https://mylogintemp.lld.isotravel.com/config.json',
    apiUrl: 'https://mylogintemp.lld.isotravel.com/api',
    translationsUrl: 'https://mylogintemp.lld.isotravel.com/',
    appTitle: 'Continuous / MEG IBE',
    traveler: {
      mockAvailable: false
    }
  },
  LLF: {
    configUrl: 'https://mylogintemp.llf.isotravel.com/config.json',
    apiUrl: 'https://mylogintemp.llf.isotravel.com/api',
    translationsUrl: 'https://mylogintemp.llf.isotravel.com/',
    appTitle: 'Continuous / MEG IBE',
    traveler: {
      mockAvailable: false
    }
  },
  LLC: {
    configUrl: 'https://mylogintemp.llc.isotravel.com/config.json',
    apiUrl: 'https://mylogintemp.llc.isotravel.com/api',
    translationsUrl: 'https://mylogintemp.llc.isotravel.com/',
    appTitle: 'Continuous / MEG IBE',
    traveler: {
      mockAvailable: false
    }
  },
  LNY: {
    configUrl: 'https://mylogintemp.lny.isotravel.com/config.json',
    apiUrl: 'https://mylogintemp.lny.isotravel.com/api',
    translationsUrl: 'https://mylogintemp.lny.isotravel.com/',
    appTitle: 'Continuous / MEG IBE',
    traveler: {
      mockAvailable: false
    }
  },
  GLH: {
    configUrl: 'https://mylogintemp.glh.isotravel.com/config.json',
    apiUrl: 'https://mylogintemp.glh.isotravel.com/api',
    translationsUrl: 'https://mylogintemp.glh.isotravel.com/',
    appTitle: 'Continuous / MEG IBE',
    traveler: {
      mockAvailable: false
    }
  },
  HPR: {
    configUrl: 'https://mylogintemp.hpr.isotravel.com/config.json',
    apiUrl: 'https://mylogintemp.hpr.isotravel.com/api',
    translationsUrl: 'https://mylogintemp.hpr.isotravel.com/',
    appTitle: 'Continuous / MEG IBE',
    traveler: {
      mockAvailable: false
    }
  },
  ATR: {
    configUrl: 'https://megibetest.atr.isotravel.com/config.json',
    apiUrl: 'https://megibetest.atr.isotravel.com/api',
    translationsUrl: 'https://megibetest.atr.isotravel.com/',
    appTitle: 'Continuous / MEG IBE',
    traveler: {
      mockAvailable: false
    }
  },
  LLW: {
    configUrl: 'https://megibetest.llw.isotravel.com/config.json',
    apiUrl: 'https://megibetest.llw.isotravel.com/api',
    translationsUrl: 'https://megibetest.llw.isotravel.com/',
    appTitle: 'Continuous / MEG IBE',
    traveler: {
      mockAvailable: false
    }
  },
  TPR: {
    configUrl: 'https://megibetest.tpr.isotravel.com/config.json',
    apiUrl: 'https://megibetest.tpr.isotravel.com/api',
    translationsUrl: 'https://megibetest.tpr.isotravel.com/',
    appTitle: 'Continuous / MEG IBE',
    traveler: {
      mockAvailable: false
    }
  },
  CWA: {
    configUrl: 'https://megibetest.cwa.isotravel.com/config.json',
    apiUrl: 'https://megibetest.cwa.isotravel.com/api',
    translationsUrl: 'https://megibetest.cwa.isotravel.com/',
    appTitle: 'Continuous / MEG IBE',
    traveler: {
      mockAvailable: false
    }
  },
  WAC: {
    configUrl: 'https://megibetest.wac.isotravel.com/config.json',
    apiUrl: 'https://megibetest.wac.isotravel.com/api',
    translationsUrl: 'https://megibetest.wac.isotravel.com/',
    appTitle: 'Continuous / MEG IBE',
    traveler: {
      mockAvailable: false
    }
  },
  LLB: {
    configUrl: 'https://megibetest.llb.isotravel.com/config.json',
    apiUrl: 'https://megibetest.llb.isotravel.com/api',
    translationsUrl: 'https://megibetest-llb.isotravel.com/',
    appTitle: 'Continuous / MEG IBE',
    traveler: {
      mockAvailable: false
    }
  }
};

const prod: LooseObject = {
  LLD: {
    configUrl: 'https://megibe.legolandholidays.de/config.json',
    apiUrl: 'https://megibe.legolandholidays.de/api',
    translationsUrl: 'https://megibe.legolandholidays.de/',
    appTitle: 'Continuous / MEG IBE',
    traveler: {
      mockAvailable: false
    }
  },
  LLC: {
    configUrl: 'https://megibe-llc.vacations.legoland.com/config.json',
    apiUrl: 'https://megibe-llc.vacations.legoland.com/api',
    translationsUrl: 'https://megibe-llc.vacations.legoland.com/',
    appTitle: 'Continuous / MEG IBE',
    traveler: {
      mockAvailable: false
    }
  },
  LLF: {
    configUrl: 'https://megibe-llf.vacations.legoland.com/config.json',
    apiUrl: 'https://megibe-llf.vacations.legoland.com/api',
    translationsUrl: 'https://megibe-llf.vacations.legoland.com/',
    appTitle: 'Continuous / MEG IBE',
    traveler: {
      mockAvailable: false
    }
  },
  LNY: {
    configUrl: 'https://megibe-lny.vacations.legoland.com/config.json',
    apiUrl: 'https://megibe-lny.vacations.legoland.com/api',
    translationsUrl: 'https://megibe-lny.vacations.legoland.com/',
    appTitle: 'Continuous / MEG IBE',
    traveler: {
      mockAvailable: false
    }
  },
  HPR: {
    configUrl: 'https://megibe.heide-park.de/config.json',
    apiUrl: 'https://megibe.heide-park.de/api',
    translationsUrl: 'https://megibe.heide-park.de/',
    appTitle: 'Continuous / MEG IBE',
    traveler: {
      mockAvailable: false
    }
  },
  GLH: {
    configUrl: 'https://megibe.gardaland.it/config.json',
    apiUrl: 'https://megibe.gardaland.it/api',
    translationsUrl: 'https://megibe.gardaland.it/',
    appTitle: 'Continuous / MEG IBE',
    traveler: {
      mockAvailable: false
    }
  },
  LLB: {
    configUrl: 'https://megibe-llb.isotravel.com/config.json',
    apiUrl: 'https://megibe-llb.isotravel.com/api',
    translationsUrl: 'https://megibe-llb.isotravel.com/',
    appTitle: 'Continuous / MEG IBE',
    traveler: {
      mockAvailable: false
    }
  },
  // MEH parks
  TPR: {
    configUrl: 'https://megibe-tpr.isotravel.com/config.json',
    apiUrl: 'https://megibe-tpr.isotravel.com/api',
    translationsUrl: 'https://megibe-tpr.isotravel.com/',
    appTitle: 'Continuous / MEG IBE',
    traveler: {
      mockAvailable: false
    }
  },
  ATR: {
    configUrl: 'https://megibe-atr.isotravel.com/config.json',
    apiUrl: 'https://megibe-atr.isotravel.com/api',
    translationsUrl: 'https://megibe-atr.isotravel.com/',
    appTitle: 'Continuous / MEG IBE',
    traveler: {
      mockAvailable: false
    }
  },
  WAC: {
    configUrl: 'https://megibe-wac.isotravel.com/config.json',
    apiUrl: 'https://megibe-wac.isotravel.com/api',
    translationsUrl: 'https://megibe-wac.isotravel.com/',
    appTitle: 'Continuous / MEG IBE',
    traveler: {
      mockAvailable: false
    }
  },
  CWA: {
    configUrl: 'https://megibe-cwa.isotravel.com/config.json',
    apiUrl: 'https://megibe-cwa.isotravel.com/api',
    translationsUrl: 'https://megibe-cwa.isotravel.com/',
    appTitle: 'Continuous / MEG IBE',
    traveler: {
      mockAvailable: false
    }
  },
  LLW: {
    configUrl: 'https://megibe-llw.isotravel.com/config.json',
    apiUrl: 'https://megibe-llw.isotravel.com/api',
    translationsUrl: 'https://megibe-llw.isotravel.com/',
    appTitle: 'Continuous / MEG IBE',
    traveler: {
      mockAvailable: false
    }
  }
};

const local: LooseObject = {
  LLD: {
    googleApiKey: 'AIzaSyCcbatBhdqlp694TJrvX-4px_t-faOwaBw',
    apiUrl: 'http://localhost:8080',
    translationsUrls: '',
    traveler: {
      mockAvailable: false
    }
  }
};

const vmtest: LooseObject = {
  LLD: {
    configUrl: 'https://newibe-test2.ibe-meg.isoad.isogmbh.de/config.json',
    apiUrl: 'https://newibe-test2.ibe-meg.isoad.isogmbh.de/api',
    translationsUrl: 'https://newibe-test2.ibe-meg.isoad.isogmbh.de/',
    appTitle: 'Continuous / MEG IBE',
    googleTagManagerContainerId: 'GTM-K9TZSMB',
    traveler: {
      mockAvailable: false
    }
  }
};

export const externalConfiguration: LooseObject = {
  LOCAL: local,
  INTTEST: isointtest,
  INTPROD: isointprod,
  VMTEST: vmtest,
  TEST: test,
  PROD: prod
};

const getExternalConfig = (
  externalParams: ExternalParamsContextProps,
  externalConfig: Partial<ConfigModel>
): Partial<ConfigModel> => {
  const { mode = 'LOCAL', brand = 'LLD' } = externalParams;

  const configModel =
    ((externalConfiguration[mode] as LooseObject)[brand] as Partial<ConfigModel> | undefined) ||
    ((externalConfiguration[mode] as LooseObject).LLD as Partial<ConfigModel>);

  return { ...externalConfig, ...configModel };
};

export default getExternalConfig;
