import * as ReactHabitat from 'react-habitat';
import AppWidget from '../widgets/AppWidget';
import IBESearchWidget from '../widgets/IBESearchWidget';

class Habitat extends ReactHabitat.Bootstrapper {
  constructor() {
    super();

    const builder = new ReactHabitat.ContainerBuilder();
    builder.register(AppWidget).as('IBEFrontend');
    builder.register(IBESearchWidget).as('IBESearchWidget');
    this.setContainer(builder.build());
  }
}

export default new Habitat();
