export enum Language {
  EN = 'en',
  EN_GB = 'en-GB',
  EN_US = 'en-US',
  DE = 'de',
  PL = 'pl',
  CS = 'cs',
  IT = 'it',
  FR = 'fr',
  PT = 'pt',
  ES = 'es',
  DA = 'da',
  RU = 'ru'
}

export enum ConfigUrlParams {
  LANGUAGE = 'lng',
  CURRENCY = 'cur'
}

export const languages = Object.values(Language);
