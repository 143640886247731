import React from 'react';
import { ApiBookedItem, ApiBooking, ApiItem, ApiPrice, ApiPriceModifierType } from '@ibe/api';
import { PriceFactory } from '@ibe/services';
import { Price } from '@ibe/components';

abstract class PriceUtil {
  public static getPrice(price: ApiPrice): JSX.Element {
    return <Price price={price} displayInline />;
  }

  public static getItemPrice(item: ApiItem): JSX.Element {
    return this.getPrice(item.price);
  }

  public static getBookingPrice(bookedItems: Array<ApiBookedItem>): JSX.Element | null {
    if (!bookedItems.length) {
      return null;
    }

    let priceSum = 0.0;

    bookedItems.forEach(bookedItem => {
      priceSum += bookedItem.price.finalPrice;
    });

    if (priceSum) {
      return this.getPrice(PriceFactory.create(priceSum, bookedItems[0].price.currencyCode));
    }
    return null;
  }

  public static getTax(price: ApiPrice | undefined): JSX.Element | null | undefined {
    let taxSum = 0.0;

    if (price && price.modifiers) {
      price.modifiers.forEach(modifier => {
        taxSum += modifier.absolute;
      });
    }

    if (taxSum) {
      return price && this.getPrice(PriceFactory.create(taxSum, price.currencyCode));
    }
    return null;
  }

  public static getTaxAmount(price: ApiPrice): number {
    let taxSum = 0.0;

    if (price && price.modifiers) {
      price.modifiers.forEach(modifier => {
        if (modifier.type === ApiPriceModifierType.TAX) {
          taxSum += modifier.absolute;
        }
      });
    }
    return taxSum;
  }

  public static getPriceOfOnlyNewItems(booking: ApiBooking): ApiPrice {
    const itemPrices = booking?.bookedItems.map(item => item.price) || [];
    return PriceFactory.fromPrices([...itemPrices]);
  }

  public static appendTaxes(source: ApiPrice, target: ApiPrice) {
    source.modifiers
      .filter(m => m.type === 'TAX')
      .forEach(modifier => {
        target.finalPrice += modifier.absolute;
      });
  }

  public static appendDiscounts(source: ApiPrice, target: ApiPrice) {
    source.modifiers
      .filter(m => m.type === ApiPriceModifierType.DISCOUNT)
      .forEach(modifier => {
        target.finalPrice -= modifier.absolute;
      });
  }
}

export default PriceUtil;
