// eslint-disable-next-line import/no-cycle
import UseNavigationMap from '../Hooks/useNavigationMap';
// eslint-disable-next-line import/no-cycle
import { b2bPages, manageBookingPages, PageUrl, workflowsEntryPoints } from '../pages';
import { ApiBooking, ApiImage } from '@ibe/api';
import BookingUtils from './BookingUtils';
import { QueryUtils, SessionStorageKeys } from './QueryUtils';
import { LocationSnapshot } from '../Layouts/BlankPageLayout';

abstract class PageUtil {
  public static pageLink(page: PageUrl): string | null {
    const navMap = UseNavigationMap();
    const pageLink = navMap.get(`${page}_key`);
    return pageLink ? pageLink[0].link : null;
  }

  public static pageLabel(page: PageUrl): string | null {
    const navMap = UseNavigationMap();

    const pageLink = navMap.get(`${page}_key`);
    return pageLink ? pageLink[0].label : null;
  }

  public static urlIsAvailable(url: string): boolean {
    const defaultPages = [PageUrl.HOME, PageUrl.LOGIN, PageUrl.LOGOUT, PageUrl.DASHBOARD];

    if (defaultPages.includes(url as PageUrl)) {
      return true;
    }

    const navMap = UseNavigationMap();
    const navKeys = [...navMap.keys()].map(key => key.slice(0, -4));

    return navKeys.includes(url);
  }

  public static getShoppingCartPagePaths(): Array<string> {
    return [PageUrl.EXTRAS_PAGE, PageUrl.CHECKOUT, PageUrl.CONFIRMATION];
  }

  public static getNoShoppingCartPagePaths(): Array<string> {
    return Object.values(PageUrl)
      .map(o => o.toString())
      .filter((p: string) => {
        return PageUtil.getShoppingCartPagePaths().indexOf(p) === -1;
      });
  }

  public static isPageAccessible(cmsTiles: Map<string, ApiImage>, pageUrl: PageUrl): boolean {
    return Array.from(cmsTiles.keys()).includes(pageUrl.substring(1));
  }

  public static buildSingleHotelListParameters(query: URLSearchParams, code?: string): string {
    return `${'?start=' + QueryUtils.getSearchStartDate(query)}${
      '&end=' + QueryUtils.getSearchEndDate(query)
    }${'&occupancy=' + QueryUtils.getOccupancy(query)}${
      '&wrk=' + QueryUtils.getWorkflowCode(query)
    }${!!code ? `&code=${code}` : ''}${
      '&salesChannelCode=' + QueryUtils.getSalesChannelCode(query)
    }`;
  }

  public static buildHotelListParametersFromBookingForRebookingWorkflow(
    booking: ApiBooking
  ): string {
    const bookedPackageCode = BookingUtils.getBookedPackageCode(booking);

    const url = `${'?code=' + bookedPackageCode || ''}${
      '&salesChannelCode=' + booking.salesChannel
    }${'&rebookingWorkflow=' + true}`;

    return url;
  }

  public static buildExtraListParameters(query: URLSearchParams): string {
    const url = `${'?start=' + QueryUtils.getSearchStartDate(query)}${
      '&end=' + QueryUtils.getSearchEndDate(query)
    }${'&occupancy=' + QueryUtils.getOccupancy(query)}${
      '&serviceTypeCode=' + QueryUtils.getExtraTypeCode(query)
    }${'&wrk=' + QueryUtils.getWorkflowCode(query)}${
      '&salesChannelCode=' + QueryUtils.getSalesChannelCode(query)
    }`;

    return url;
  }

  public static getWorkflowEntryPoint(
    currentLocation: LocationSnapshot,
    isLoggedIn: boolean
  ): string {
    const navigationHistory: LocationSnapshot[] = this.getNavigationHistory();

    if (
      manageBookingPages.includes(currentLocation.pathname as PageUrl) ||
      b2bPages.includes(currentLocation.pathname as PageUrl)
    ) {
      return `${PageUrl.LOGOUT}${currentLocation.search}`;
    }

    if (isLoggedIn) {
      return `${PageUrl.LOGOUT}${currentLocation.search}`;
    }

    const reversedHistoryStack = navigationHistory.reverse();

    for (const location of reversedHistoryStack) {
      if (workflowsEntryPoints.includes(location.pathname as PageUrl)) {
        return `${location.pathname}${location.search}`;
      }
    }
    return `${PageUrl.PACKAGE}${currentLocation.search}`;
  }

  private static getNavigationHistory(): LocationSnapshot[] {
    const savedHistory = sessionStorage.getItem(SessionStorageKeys.NAVIGATION);
    if (savedHistory) {
      return JSON.parse(savedHistory);
    }
    return [];
  }
}

export default PageUtil;
