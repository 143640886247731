import { ConfigModel } from '@ibe/services';
import { ExternalParamsContextProps } from './ExternalParamsContext';

interface LooseObject {
  [key: string]: unknown;
}

const isointtest: LooseObject = {
  LLD: {
    configUrl: 'https://megibe-lld-test.ops.local/tupvid-config.json',
    apiUrl: 'https://megibe-lld-test.ops.local/tupvideo'
  }
};

const isointprod: LooseObject = {
  LLD: {
    configUrl: 'http://newibe-cont.ibe-meg.isoad.isogmbh.de/tupvid-config.json',
    apiUrl: 'http://ibe-meg.isoad.isogmbh.de:58080'
  }
};

const test: LooseObject = {
  LLD: {
    configUrl: 'https://mylogintemp.lld.isotravel.com/tupvid-config.json',
    apiUrl: 'https://mylogintemp.lld.isotravel.com/tupvideo'
  }
};

const prod: LooseObject = {
  LLD: {
    configUrl: 'https://megibe.legolandholidays.de/tupvid-config.json',
    apiUrl: 'https://megibe.legolandholidays.de/tupvideo'
  }
};

const local: LooseObject = {
  LLD: {
    apiUrl: 'http://localhost:8081/'
  }
};

const vmtest: LooseObject = {
  LLD: {
    configUrl: 'https://megibe-lld-test.ops.local/tupvid-config.json',
    apiUrl: 'https://megibe-lld-test.ops.local/tupvideo'
  }
};

export const externalConfiguration: LooseObject = {
  LOCAL: local,
  INTTEST: isointtest,
  INTPROD: isointprod,
  VMTEST: vmtest,
  TEST: test,
  PROD: prod
};

const getTupvidExternalConfig = (
  externalParams: ExternalParamsContextProps,
  externalConfig: Partial<ConfigModel>
): Partial<ConfigModel> => {
  const { mode = 'LOCAL', brand = 'LLD' } = externalParams;

  const configModel =
    ((externalConfiguration[mode] as LooseObject)[brand] as Partial<ConfigModel> | undefined) ||
    ((externalConfiguration[mode] as LooseObject).LLD as Partial<ConfigModel>);

  return { ...externalConfig, ...configModel };
};

export default getTupvidExternalConfig;
