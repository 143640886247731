import React, { useEffect, useState } from 'react';

import { Spinner } from './Spinner';
import { Overlay } from '@ibe/components';

interface Props {
  delay?: number;
}

export const LoadingOverlay = (props: Props): JSX.Element => {
  const [show, setShow] = useState(false);
  const { delay = 300 } = props;

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
    }, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [delay]);

  return show ? (
    <div className="transparent-overlay mt-2" style={{ height: '95px', position: 'relative' }}>
      <Overlay fullOpacity customSpinner={<Spinner />} />
    </div>
  ) : (
    <></>
  );
};
