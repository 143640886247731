import React from 'react';
import { Route, useHistory, RouteProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { ApiRole } from '@ibe/api';
import { useAuth } from '@ibe/components';
import { useMount } from 'react-use';
import { PageUrl } from '../pages';

interface Props {
  redirectTo: string;
  children: React.ReactChild;
  requiredRoles: ApiRole[];
}

const ProtectedRoute = observer(
  ({ children, requiredRoles, redirectTo, ...routeProps }: Props & RouteProps) => {
    const authHook = useAuth();
    const history = useHistory();
    const { token } = authHook.authState;

    useMount(() => {
      if (token) {
        authHook.authService.fromJwt(token);
        if (
          requiredRoles.length > 0 &&
          authHook.authService.state.role !== null &&
          requiredRoles.includes(authHook.authService.state.role)
          // eslint-disable-next-line no-empty
        ) {
        } else {
          history.push(PageUrl.LOGIN);
        }
      } else {
        history.push(PageUrl.LOGIN);
      }
    });

    // eslint-disable-next-line react/jsx-props-no-spreading,@typescript-eslint/explicit-function-return-type
    return <Route {...routeProps} render={() => children} />;
  }
);

export default ProtectedRoute;
