import Analytics from 'analytics';
import googleAnalytics from '@analytics/google-analytics';
import googleTagManager from '@analytics/google-tag-manager';

import { hotjar } from 'react-hotjar';

import packageJson from '../../package.json';
import { isDev } from '../env';
import Cookie, { CookieType } from '../Cookie/Cookie';

const analyticsOptions = {
  app: packageJson.name,
  version: packageJson.version,
  debug: isDev,
  plugins: []
};

export let tracking = Analytics(analyticsOptions);

export const enableTracking = config => {
  analyticsOptions.plugins = [];
  tracking = Analytics(analyticsOptions);

  if (config.googleAnalyticsTrackingId && Cookie.hasType(CookieType.PERFORMANCE)) {
    analyticsOptions.plugins.push(
      googleAnalytics({
        trackingId: config.googleAnalyticsTrackingId
      })
    );
  }

  if (config.googleTagManagerContainerId && Cookie.hasType(CookieType.PERFORMANCE)) {
    analyticsOptions.plugins.push(
      googleTagManager({
        containerId: config.googleTagManagerContainerId
      })
    );
  }

  if (
    config.tracking.hotjar.hjid &&
    config.tracking.hotjar.hjsv &&
    Cookie.hasType(CookieType.PERFORMANCE)
  ) {
    hotjar.initialize(config.tracking.hotjar.hjid, config.tracking.hotjar.hjsv);
  }

  tracking = Analytics(analyticsOptions);
};
