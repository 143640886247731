import { Channel } from '@ibe/services';
import PackageListEvent from './Payload/PackageListEvent';
import ExtraComponentListEvent from './Payload/ExtraComponentListEvent';
import InsuranceAddedEvent from './Payload/InsuranceAddedEvent';
import BookingRemoveItemEvent from './Payload/BookingRemoveItemEvent';

export const packageListEvent = new Channel<PackageListEvent>(
  PackageListEvent,
  'package-list-event'
);

export const itemExtraComponentListEvent = new Channel<ExtraComponentListEvent>(
  ExtraComponentListEvent,
  'extra-component-list-event'
);

export const insurancePopupAddedEvent = new Channel<InsuranceAddedEvent>(
  InsuranceAddedEvent,
  'insurance-added-popup'
);

export const insuranceCheckoutAddedEvent = new Channel<InsuranceAddedEvent>(
  InsuranceAddedEvent,
  'insurance-added-checkout'
);

export const bookingItemRemovedEvent = new Channel<BookingRemoveItemEvent>(
  BookingRemoveItemEvent,
  'booking-item-removed'
);
