import { useContext } from 'react';
import { useLocalStore } from 'mobx-react';
import CmsContext from '../Contexts/CmsContext';
import CmsService from '../Services/CmsService';
import { useAuth, useConfig } from '@ibe/components';

function useCms(): CmsService {
  const context = useContext(CmsContext);
  const config = useConfig();
  const cmsService: CmsService = useLocalStore<CmsService>(() => context);

  cmsService.apiUrl = config.apiUrl;
  const authHook = useAuth();
  const { token } = authHook.authState;
  if (token) {
    cmsService.setToken = token;
  }

  return cmsService;
}

export default useCms;
