import React from 'react';

import { ApiRole } from '@ibe/api';
import { LoggerFactory } from '@ibe/services';
import Page from './Models/Page';

const logger = LoggerFactory.get('MMB-Pages');

export enum PageUrl {
  HOME = '/',
  LOGIN = '/login',
  REGISTER = '/register',
  PASSWORD_CHANGE = '/changePassword',
  LOGOUT = '/logout',
  DASHBOARD = '/dashboard',
  HEALTH = '/health',

  MANAGE_BOOKING = '/manage-booking',
  DOCUMENTS = '/documents',
  PAYMENT_STATUS = '/payment-status',
  CHANGE_BOOKING = '/change-booking',
  EXTRA_SERVICES = '/extra-services',
  BOOKING_NOTES = '/booking-notes',

  VOUCHER_LIST = '/vouchers',
  VOUCHER_DETAILS = '/voucherDetails',
  VOUCHER_CHECKOUT = '/voucherCheckout',
  VIDEO = '/video',
  PACKAGE = '/package',
  HOTEL_LIST = '/hotels',
  HOTEL_WORKFLOW = '/singleHotel',
  EXTRAS_PAGE = '/extras',
  CHECKOUT = '/checkout',
  HOTEL_WORKFLOW_CHECKOUT = '/hotelCheckout',
  CONFIRMATION = '/confirmation',
  AGENCY_PROFILE = '/profile',
  AGENCY_USER = '/agencyUser',
  BOOKINGS = '/bookings',
  BOOKING_DETAILS = '/bookingDetails',

  EXTRAS_TYPE_LIST = '/products',
  EXTRAS_LIST_PAGE = '/extrasList',
  EXTRAS_CHECKOUT = '/extrasCheckout',

  INFORMATION = '/information',

  PAGE404 = '/notFound'
}

export const essentialPages: Array<PageUrl> = [
  PageUrl.HOME,
  PageUrl.DASHBOARD,
  PageUrl.LOGIN,
  PageUrl.LOGOUT,
  PageUrl.REGISTER,
  PageUrl.PASSWORD_CHANGE
];

export const manageBookingPages: PageUrl[] = [
  PageUrl.LOGIN,
  PageUrl.REGISTER,
  PageUrl.PASSWORD_CHANGE,
  PageUrl.LOGOUT,
  PageUrl.DASHBOARD,
  PageUrl.MANAGE_BOOKING,
  PageUrl.DOCUMENTS,
  PageUrl.PAYMENT_STATUS,
  PageUrl.CHANGE_BOOKING,
  PageUrl.EXTRA_SERVICES,
  PageUrl.BOOKING_NOTES,
  PageUrl.INFORMATION
];

export const innerManageBookingPages: PageUrl[] = [
  PageUrl.DASHBOARD,
  PageUrl.MANAGE_BOOKING,
  PageUrl.DOCUMENTS,
  PageUrl.PAYMENT_STATUS,
  PageUrl.CHANGE_BOOKING,
  PageUrl.EXTRA_SERVICES,
  PageUrl.BOOKING_NOTES,
  PageUrl.INFORMATION
];

export const b2bPages: PageUrl[] = [
  PageUrl.AGENCY_PROFILE,
  PageUrl.BOOKINGS,
  PageUrl.BOOKING_DETAILS
];

export const singleHotelBookingPages: PageUrl[] = [
  PageUrl.HOTEL_WORKFLOW,
  PageUrl.HOTEL_WORKFLOW_CHECKOUT
];

export const extrasWorkflowPages: PageUrl[] = [
  PageUrl.EXTRAS_TYPE_LIST,
  PageUrl.EXTRAS_LIST_PAGE,
  PageUrl.EXTRAS_CHECKOUT
];

export const workflowsEntryPoints: PageUrl[] = [
  PageUrl.DASHBOARD,

  PageUrl.PACKAGE,

  // hotel_list page handles both list of hotels and details
  PageUrl.HOTEL_LIST,

  PageUrl.HOTEL_WORKFLOW,

  PageUrl.EXTRAS_TYPE_LIST,
  PageUrl.EXTRAS_LIST_PAGE,

  PageUrl.VOUCHER_LIST,
  PageUrl.VOUCHER_DETAILS
];

export const pagesWithCurrencyChangeOption: PageUrl[] = [PageUrl.HOTEL_LIST];

const Home = React.lazy(() => import('./Pages/Home/Home'));
const HotelList = React.lazy(() => import('./Pages/PackageBooking/Hotels/PackageHotelsPage'));
const Login = React.lazy(() => import('./Pages/MyLogin/Login/Login'));
const Register = React.lazy(() => import('./Pages/B2B/Register/Register'));
const PasswordChange = React.lazy(() => import('./Pages/B2B/PasswordChange/PasswordChange'));
const Logout = React.lazy(() => import('./Pages/MyLogin/Logout/Logout'));
const Health = React.lazy(() => import('./Pages/Health'));
const VoucherList = React.lazy(() => import('./Pages/Voucher/VoucherList/VoucherListPage'));
const VoucherDetails = React.lazy(
  () => import('./Pages/Voucher/VoucherDetails/VoucherDetailsPage')
);
const VoucherCheckout = React.lazy(
  () => import('./Pages/Voucher/VoucherCheckout/VoucherCheckoutPage')
);
const Video = React.lazy(() => import('./Pages/MyLogin/Video/VideoPage'));
const Package = React.lazy(() => import('./Pages/PackageBooking/Package/PackagePage'));
const Checkout = React.lazy(() => import('./Pages/PackageBooking/Checkout/CheckoutPage'));
const HotelCheckout = React.lazy(
  () => import('./Pages/SingleHotelBooking/Checkout/SingleHotelWorkflowCheckoutPage')
);
const Dashboard = React.lazy(() => import('./Pages/MyLogin/Dashboard/Dashboard'));
const ManageBooking = React.lazy(() => import('./Pages/MyLogin/ManageBooking/ManageBooking'));
const Documents = React.lazy(() => import('./Pages/MyLogin/Documents/Documents'));
const PaymentStatus = React.lazy(() => import('./Pages/MyLogin/PaymentStatus/PaymentStatus'));
const ChangeBooking = React.lazy(() => import('./Pages/MyLogin/ChangeBooking/ChangeBooking'));

const ExtraServices = React.lazy(() => import('./Pages/MyLogin/ExtraServices/ExtraServices'));
const BookingNotes = React.lazy(() => import('./Pages/MyLogin/BookingNotes/BookingNotes'));
const InformationDefinitions = React.lazy(
  () => import('./Pages/MyLogin/InformationDefinitions/InformationDefinitions')
);
const HotelWorkflow = React.lazy(
  () => import('./Pages/SingleHotelBooking/Hotels/SingleHotelWorkflowHotelsPage')
);
const ExtrasPage = React.lazy(() => import('./Pages/PackageBooking/Extras/ExtrasPage'));
const Confirmation = React.lazy(() => import('./Pages/ConfirmationPage'));
const AgencyProfile = React.lazy(() => import('./Pages/B2B/AgencyProfile/AgencyProfilePage'));
const AgencyUser = React.lazy(() => import('./Pages/B2B/AgencyUser/AgencyUser'));
const Bookings = React.lazy(() => import('./Pages/B2B/Bookings/BookingsPage'));
const BookingDetails = React.lazy(() => import('./Pages/B2B/BookingDetails/BookingDetails'));
const ExtrasTypeList = React.lazy(
  () => import('./Pages/ExtrasWorkflow/ExtrasTypeList/ExtrasTypeListPage')
);
const ExtrasListPage = React.lazy(
  () => import('./Pages/ExtrasWorkflow/ExtrasListPage/ExtrasListPage')
);
const ExtrasCheckout = React.lazy(
  () => import('./Pages/ExtrasWorkflow/ExtrasCheckout/ExtrasCheckoutPage')
);

const NotFound = React.lazy(() => import('./Pages/NotFound'));

export const pages: Record<PageUrl, Page> = {
  [PageUrl.HOME]: {
    component: <Home />
  },

  [PageUrl.LOGIN]: {
    component: <Login />
  },

  [PageUrl.REGISTER]: {
    component: <Register />
  },

  [PageUrl.PASSWORD_CHANGE]: {
    component: <PasswordChange />
  },

  [PageUrl.LOGOUT]: {
    component: <Logout />
  },

  [PageUrl.HEALTH]: {
    component: <Health />
  },

  [PageUrl.VOUCHER_LIST]: {
    component: <VoucherList />
  },

  [PageUrl.VOUCHER_DETAILS]: {
    component: <VoucherDetails />
  },

  [PageUrl.VOUCHER_CHECKOUT]: {
    component: <VoucherCheckout />
  },

  [PageUrl.VIDEO]: {
    component: <Video />
  },

  [PageUrl.PACKAGE]: {
    component: <Package />
  },

  [PageUrl.CHECKOUT]: {
    component: <Checkout />
  },

  [PageUrl.HOTEL_WORKFLOW_CHECKOUT]: {
    component: <HotelCheckout />
  },

  [PageUrl.DASHBOARD]: {
    component: <Dashboard />,
    requiredRoles: [ApiRole.CONSUMER]
  },

  [PageUrl.MANAGE_BOOKING]: {
    component: <ManageBooking />
  },

  [PageUrl.DOCUMENTS]: {
    component: <Documents />,
    requiredRoles: [ApiRole.CONSUMER]
  },

  [PageUrl.PAYMENT_STATUS]: {
    component: <PaymentStatus />,
    requiredRoles: [ApiRole.CONSUMER]
  },

  [PageUrl.CHANGE_BOOKING]: {
    component: <ChangeBooking />,
    requiredRoles: [ApiRole.CONSUMER]
  },

  [PageUrl.EXTRA_SERVICES]: {
    component: <ExtraServices />,
    requiredRoles: [ApiRole.CONSUMER],
    tileId: 'extraServices'
  },

  [PageUrl.BOOKING_NOTES]: {
    component: <BookingNotes />,
    requiredRoles: [ApiRole.CONSUMER]
  },

  [PageUrl.HOTEL_LIST]: {
    component: <HotelList />
  },

  [PageUrl.HOTEL_WORKFLOW]: {
    component: <HotelWorkflow />
  },

  [PageUrl.EXTRAS_PAGE]: {
    component: <ExtrasPage />
  },

  [PageUrl.CONFIRMATION]: {
    component: <Confirmation />
  },

  [PageUrl.AGENCY_PROFILE]: {
    component: <AgencyProfile />,
    requiredRoles: [ApiRole.AGENCYOWNER, ApiRole.AGENT]
  },

  [PageUrl.AGENCY_USER]: {
    component: <AgencyUser />,
    requiredRoles: [ApiRole.AGENCYOWNER]
  },

  [PageUrl.BOOKINGS]: {
    component: <Bookings />,
    requiredRoles: [ApiRole.AGENCYOWNER, ApiRole.AGENT]
  },

  [PageUrl.BOOKING_DETAILS]: {
    component: <BookingDetails />,
    requiredRoles: [ApiRole.AGENCYOWNER, ApiRole.AGENT]
  },

  [PageUrl.EXTRAS_TYPE_LIST]: {
    component: <ExtrasTypeList />
  },

  [PageUrl.EXTRAS_LIST_PAGE]: {
    component: <ExtrasListPage />
  },

  [PageUrl.EXTRAS_CHECKOUT]: {
    component: <ExtrasCheckout />
  },

  [PageUrl.INFORMATION]: {
    component: <InformationDefinitions />
  },

  [PageUrl.PAGE404]: {
    component: <NotFound />
  }
};

export const enum SUB_PAGES {}

export const getPageByUrl = (url: PageUrl | string): Page => {
  if (Object.prototype.hasOwnProperty.call(pages, url)) {
    return pages[url as PageUrl];
  }

  logger.error(`No page found for url ${url}`);
  return pages[PageUrl.HOME];
};
