enum CustomTrackingEvent {
  PACKAGE_LIST_VIEW = 'PACKAGE_LIST_VIEW',
  PRODUCT_EXTRA_VIEW = 'PRODUCT_EXTRA_VIEW',
  CART_CHECKOUT_START = 'CART_CHECKOUT_START',
  CART_CHECKOUT_FINISH = 'CART_CHECKOUT_FINISH',
  INSURANCE_ADDED_POPUP = 'INSURANCE_ADDED_POPUP',
  INSURANCE_ADDED_CHECKOUT = 'INSURANCE_ADDED_CHECKOUT'
}

export default CustomTrackingEvent;
