import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { ApiErrorResponseFromJSON, ApiImage, ApiImageFromJSON } from '@ibe/api';
import { removeTrailingSlashes, LoggerFactory } from '@ibe/services';
import { VoucherCancellationType } from '@ibe/components';
import Workflow from '../Models/CMS/Workflow';
import IbeConfig from '../Models/CMS/IbeConfig';
import CmsInsurance from '../Models/CMS/CmsInsurance';
import CmsExtraGroup from '../Models/CMS/CmsExtraGroup';
import CmsClosureDate from '../Models/CMS/CmsClosureDate';
import PackageWorkflow from '../Models/CMS/PackageWorkflow';
import HotelWorkflow from '../Models/CMS/HotelWorkflow';
import ExtraWorkflow from '../Models/CMS/ExtraWorkflow';
import CmsExtraType from '../Models/CMS/CmsExtraType';
import CmsExtra from '../Models/CMS/CmsExtra';
import CmsOffer from '../Models/CMS/CmsOffer';
import i18next from 'i18next';
import FeatureConfiguration from '../Models/CMS/FeatureConfiguration';
import CMSInformationDefinition from '../Models/CMS/CMSInformationDefinition';
import CmsFlexBox from '../Models/CMS/CmsFlexBox';
import { CountdownConfiguration } from '../Models/CountdownConfiguration';

const logger = LoggerFactory.get('CmsService');

class CmsService {
  public _isBusy = false;

  public _dashboardTiles: Map<string, ApiImage> = new Map<string, ApiImage>();

  public _featureConfiguration: FeatureConfiguration = new FeatureConfiguration();

  public _countdownConfiguration: CountdownConfiguration | undefined;

  public _isBookingFulfillingRebookingRules: boolean;

  public _isBookingFulfillingCancellationRules: Map<VoucherCancellationType, boolean>;

  public _availablePaymentMethods: Map<string, string> = new Map<string, string>();

  public _workflows: Workflow[] = [];

  public _ibeConfig: IbeConfig = new IbeConfig();

  public _extraGroups: CmsExtraGroup[] = [];

  public _isMyLoginActivated: boolean;

  public _myLoginLogoutUrl: string;

  public _insurance: CmsInsurance;

  public _closureDates: CmsClosureDate[] = [];

  public _extraTypes: CmsExtraType[] = [];

  public _extras: CmsExtra[] = [];

  public _offers: CmsOffer[] = [];

  public _informationDefinitions: CMSInformationDefinition[] = [];

  public _flexBoxes: CmsFlexBox[] = [];

  private _apiUrl: string;

  private _token: string;

  private dashboardTilesUrl = '/cms/ossp/dashboard/tiles';

  private countdownConfigurationUrl = '/cms/ossp/dashboard/countdownConfiguration';

  private featureConfigurationUrl = '/cms/ossp/feature/config';

  private rebookingRulesUrl = '/cms/ossp/rebookingRules';

  private logoutUrl = '/cms/ossp/logoutUrl';

  private cancellationRulesUrl = '/cms/ossp/cancellationRules';

  private informationDefinitionsUrl = '/cms/ossp/informationDefinitions';

  private myLoginActivationUrl = '/cms/ossp/myLoginActivation';

  private flexBoxesUrl = '/cms/ossp/flexboxes';

  private workflowParametersUrl = '/cms/workflows';

  private ibeConfigurationUrl = '/cms/ibeConfig';

  private loadingImageUrl = '/cms/assets/loadingImage';

  private insuranceUrl = '/cms/insurance';

  private extraGroupsUrl = '/cms/extraGroups';

  private closureDatesUrl = '/cms/closureDates';

  private extraTypesUrl = '/cms/extraTypes';

  private offersUrl = '/cms/offers';

  constructor() {
    makeObservable(this, {
      _dashboardTiles: observable,
      _isBusy: observable,
      _featureConfiguration: observable,
      _isBookingFulfillingRebookingRules: observable,
      _isBookingFulfillingCancellationRules: observable,
      _availablePaymentMethods: observable,
      _countdownConfiguration: observable,
      _workflows: observable,
      _ibeConfig: observable,
      _isMyLoginActivated: observable,
      _myLoginLogoutUrl: observable,
      _insurance: observable,
      _extraGroups: observable,
      _closureDates: observable,
      _extraTypes: observable,
      _extras: observable,
      _offers: observable,
      _flexBoxes: observable,
      _informationDefinitions: observable,
      dashboardTiles: computed,
      featureConfiguration: computed,
      isBusy: computed,
      isBookingFulfillingRebookingRules: computed,
      isBookingFulfillingCancellationRules: computed,
      availablePaymentMethods: computed,
      workflows: computed,
      ibeConfig: computed,
      insurance: computed,
      extraGroups: computed,
      closureDates: computed,
      extraTypes: computed,
      extras: computed,
      offers: computed,
      informationDefinitions: computed,
      hasOffers: computed,
      flexBoxes: computed,
      fetchDashboardTiles: action,
      fetchBookingFulfillsRebookingRules: action,
      fetchFeatureConfiguration: action,
      fetchAvailablePaymentMethods: action,
      fetchPackageWorkflow: action,
      fetchIbeConfiguration: action,
      fetchMyLoginActivation: action,
      fetchMyLoginLogoutUrl: action,
      fetchExtraGroups: action,
      fetchInsurance: action,
      fetchOffers: action,
      fetchInformationDefinitions: action,
      fetchFlexBoxes: action
    });
  }

  get isBusy(): boolean {
    return this._isBusy;
  }

  get dashboardTiles(): Map<string, ApiImage> {
    return this._dashboardTiles ? this._dashboardTiles : new Map<string, ApiImage>();
  }

  get featureConfiguration(): FeatureConfiguration {
    return this._featureConfiguration ? this._featureConfiguration : new FeatureConfiguration();
  }

  get isBookingFulfillingRebookingRules(): boolean {
    return this._isBookingFulfillingRebookingRules
      ? this._isBookingFulfillingRebookingRules
      : false;
  }

  get isBookingFulfillingCancellationRules(): Map<VoucherCancellationType, boolean> {
    return this._isBookingFulfillingCancellationRules
      ? this._isBookingFulfillingCancellationRules
      : new Map<VoucherCancellationType, boolean>();
  }

  get availablePaymentMethods(): Map<string, string> {
    return this._availablePaymentMethods
      ? this._availablePaymentMethods
      : new Map<string, string>();
  }

  get workflows(): Array<Workflow> {
    return this._workflows ? this._workflows : [];
  }

  get ibeConfig(): IbeConfig {
    return this._ibeConfig ? this._ibeConfig : new IbeConfig();
  }

  get insurance(): CmsInsurance {
    return this._insurance ? this._insurance : new CmsInsurance();
  }

  get extraGroups(): CmsExtraGroup[] {
    return this._extraGroups ? this._extraGroups : [];
  }

  get closureDates(): CmsClosureDate[] {
    return this._closureDates ? this._closureDates : [];
  }

  get extraTypes(): CmsExtraType[] {
    return this._extraTypes ? this._extraTypes : [];
  }

  get extras(): CmsExtra[] {
    return this._extras ? this._extras : [];
  }

  get offers(): CmsOffer[] {
    return this._offers ? this._offers : [];
  }

  get informationDefinitions(): CMSInformationDefinition[] {
    return this._informationDefinitions ? this._informationDefinitions : [];
  }

  get hasOffers(): boolean {
    return this._offers.length > 0;
  }

  get flexBoxes(): CmsFlexBox[] {
    return this._flexBoxes ? this._flexBoxes : [];
  }

  get countdownConfiguration(): CountdownConfiguration | undefined {
    return this._countdownConfiguration;
  }

  set apiUrl(value: string) {
    this._apiUrl = removeTrailingSlashes(value);
  }

  set setToken(value: string) {
    this._token = value;
  }

  private fetchLocalized = async (path: string, requestOptions?: RequestInit) => {
    return fetch(
      path,
      requestOptions || {
        headers: {
          'Accept-Language': i18next.language
        }
      }
    );
  };

  findPackageWorkflow(workflows: Array<Workflow>): PackageWorkflow {
    return workflows.find(w => w.type === 'PackageWorkflow') as PackageWorkflow;
  }

  findHotelWorkflow(workflows: Array<Workflow>): HotelWorkflow {
    return workflows.find(w => w.type === 'HotelWorkflow') as HotelWorkflow;
  }

  findExtraWorkflow(workflows: Array<Workflow>): ExtraWorkflow {
    return workflows.find(w => w.type === 'ExtraWorkflow') as ExtraWorkflow;
  }

  async fetchDashboardTiles(): Promise<Map<string, ApiImage>> {
    this._isBusy = true;
    try {
      const response = await this.fetchLocalized(this._apiUrl + this.dashboardTilesUrl);
      const jsonResponse = await response.json();
      const result = new Map();
      jsonResponse.map((value: { key: string; image: ApiImage }) => {
        result.set(value.key, ApiImageFromJSON(value.image));
      });
      this._isBusy = false;
      runInAction(() => {
        this._dashboardTiles = result;
      });
      return this._dashboardTiles;
    } catch (e) {
      this._isBusy = false;
      throw ApiErrorResponseFromJSON(e);
    }
  }

  async fetchCountdownConfiguration(): Promise<CountdownConfiguration | undefined> {
    this._isBusy = true;
    try {
      const response = await this.fetchLocalized(this._apiUrl + this.countdownConfigurationUrl);
      const jsonResponse = await response.json();
      this._countdownConfiguration = jsonResponse;
      this._isBusy = false;
      return this._countdownConfiguration;
    } catch (e) {
      this._countdownConfiguration = undefined;
      this._isBusy = false;
      throw ApiErrorResponseFromJSON(e);
    }
  }

  async fetchFeatureConfiguration(): Promise<FeatureConfiguration> {
    this._isBusy = true;
    try {
      const response = await fetch(this._apiUrl + this.featureConfigurationUrl);
      const jsonResponse = await response.json();
      Object.assign(this._featureConfiguration, jsonResponse);
      this._isBusy = false;
      return this._featureConfiguration;
    } catch (e) {
      this._featureConfiguration = new FeatureConfiguration();
      this._isBusy = false;
      throw ApiErrorResponseFromJSON(e);
    }
  }

  async fetchBookingFulfillsRebookingRules(bookingNumber: string): Promise<boolean> {
    this._isBusy = true;
    try {
      const requestOptions = {
        method: 'GET',
        headers: { 'X-IBE-Authorization': this._token }
      };
      const response = await fetch(
        `${this._apiUrl + this.rebookingRulesUrl}/${bookingNumber}`,
        requestOptions
      );

      this._isBookingFulfillingRebookingRules = await response.json();
      this._isBusy = false;
      return this._isBookingFulfillingRebookingRules;
    } catch (e) {
      this._isBookingFulfillingRebookingRules = false;
      this._isBusy = false;
      throw ApiErrorResponseFromJSON(e);
    }
  }

  async fetchMyLoginLogoutUrl(): Promise<string | null> {
    try {
      const response = await this.fetchLocalized(this._apiUrl + this.logoutUrl);
      if (response) {
        this._myLoginLogoutUrl = await response.text();
      }
      return this._myLoginLogoutUrl;
    } catch (e) {
      return null;
    }
  }

  async fetchBookingFulfillsCancellationRules(
    bookingNumber: string
  ): Promise<Map<VoucherCancellationType, boolean>> {
    this._isBusy = true;
    const result = new Map<VoucherCancellationType, boolean>();
    try {
      const requestOptions = {
        method: 'GET',
        headers: { 'X-IBE-Authorization': this._token, 'Accept-Language': i18next.language }
      };
      const response = await this.fetchLocalized(
        `${this._apiUrl + this.cancellationRulesUrl}/${bookingNumber}`,
        requestOptions
      );
      const cancellationRules = await response.json();
      result.set(VoucherCancellationType.ENTIRE, cancellationRules[VoucherCancellationType.ENTIRE]);
      result.set(
        VoucherCancellationType.VOUCHER,
        cancellationRules[VoucherCancellationType.VOUCHER]
      );
      this._isBookingFulfillingCancellationRules = result;
      this._isBusy = false;
      return this._isBookingFulfillingCancellationRules;
    } catch (e) {
      result.set(VoucherCancellationType.ENTIRE, false);
      result.set(VoucherCancellationType.VOUCHER, false);
      this._isBookingFulfillingCancellationRules = result;
      this._isBusy = false;
      throw ApiErrorResponseFromJSON(e);
    }
  }

  async fetchAvailablePaymentMethods(): Promise<Map<string, string>> {
    return new Map<string, string>();
  }

  async fetchWorkflows(name: string): Promise<Array<Workflow>> {
    try {
      const response = await this.fetchLocalized(
        this._apiUrl + this.workflowParametersUrl + '/' + name
      );
      if (response) {
        const jsonResponse = await response.json();
        if (
          jsonResponse &&
          Object.entries(jsonResponse) &&
          Object.entries(jsonResponse).length > 0 &&
          Array.isArray(jsonResponse)
        ) {
          console.debug('jsonResponse', jsonResponse);
          this._workflows = jsonResponse;
        }
      }
      console.debug('this._workflows', this._workflows);
      return this._workflows;
    } catch (e) {
      this._workflows = [];
      this._isBusy = false;
      console.warn('No workflows found: ', e);
      return this._workflows;
    }
  }

  async fetchPackageWorkflow(name: string): Promise<PackageWorkflow> {
    if (
      this._workflows &&
      this._workflows.length > 0 &&
      !!this._workflows.find(workflow => workflow.name === name)
    ) {
      return this.findPackageWorkflow(this._workflows);
    }
    return this.findPackageWorkflow(await this.fetchWorkflows(name));
  }

  async fetchHotelWorkflow(name: string): Promise<HotelWorkflow> {
    if (this._workflows) {
      return this.findHotelWorkflow(this._workflows);
    }
    return this.findHotelWorkflow(await this.fetchWorkflows(name));
  }

  async fetchExtraWorkflow(name: string): Promise<ExtraWorkflow> {
    if (this._workflows && this.findExtraWorkflow(this._workflows)) {
      return this.findExtraWorkflow(this._workflows);
    }
    return this.findExtraWorkflow(await this.fetchWorkflows(name));
  }

  async fetchIbeConfiguration(): Promise<IbeConfig> {
    this._isBusy = true;
    try {
      const response = await this.fetchLocalized(this._apiUrl + this.ibeConfigurationUrl);
      if (response) {
        const jsonResponse = await response.json();
        if (
          jsonResponse &&
          Object.entries(jsonResponse) &&
          Object.entries(jsonResponse).length > 0
        ) {
          this._ibeConfig = jsonResponse;
        }
      }
      this._isBusy = false;
      return this._ibeConfig;
    } catch (e) {
      this._ibeConfig = new IbeConfig();
      this._isBusy = false;
      throw ApiErrorResponseFromJSON(e);
    }
  }

  async fetchMyLoginActivation(): Promise<boolean> {
    this._isBusy = true;
    try {
      const response = await this.fetchLocalized(this._apiUrl + this.myLoginActivationUrl);
      if (response) {
        this._isMyLoginActivated = await response.json();
      }
      this._isBusy = false;
      return this._isMyLoginActivated;
    } catch (e) {
      this._isMyLoginActivated = false;
      this._isBusy = false;
      throw ApiErrorResponseFromJSON(e);
    }
  }

  async fetchLoadingImage(): Promise<string> {
    try {
      const response = await fetch(this._apiUrl + this.loadingImageUrl);
      if (response) {
        const path = await response.text();
        return path;
      }
      return Promise.reject();
    } catch (e) {
      throw ApiErrorResponseFromJSON(e);
    }
  }

  async fetchInsurance(code: string): Promise<CmsInsurance> {
    this._isBusy = true;
    try {
      const response = await this.fetchLocalized(this._apiUrl + this.insuranceUrl + '/' + code);
      if (response) {
        this._insurance = await response.json();
      }
      this._isBusy = false;
      return this._insurance;
    } catch (e) {
      this._insurance = new CmsInsurance();
      this._isBusy = false;
      throw ApiErrorResponseFromJSON(e);
    }
  }

  async fetchExtraGroups(): Promise<CmsExtraGroup[]> {
    this._isBusy = true;
    try {
      const response = await this.fetchLocalized(this._apiUrl + this.extraGroupsUrl);
      if (response && response.status !== 204) {
        const jsonResponse = await response.json();
        if (
          jsonResponse &&
          Object.entries(jsonResponse) &&
          Object.entries(jsonResponse).length > 0
        ) {
          this._extraGroups = jsonResponse;
        }
      }
      this._isBusy = false;
      return this._extraGroups;
    } catch (exception) {
      this._extraGroups = [];
      this._isBusy = false;
      throw ApiErrorResponseFromJSON(exception);
    }
  }

  async fetchClosureDates(): Promise<CmsClosureDate[]> {
    this._isBusy = true;
    try {
      const response = await this.fetchLocalized(this._apiUrl + this.closureDatesUrl);
      if (response) {
        this._closureDates = await response.json();
      }
      this._isBusy = false;
      return this._closureDates;
    } catch (e) {
      this._closureDates = [];
      this._isBusy = false;
      throw ApiErrorResponseFromJSON(e);
    }
  }

  async fetchExtraType(serviceTypeCode: string): Promise<CmsExtraType | undefined> {
    let result = this._extraTypes.find(t => t.serviceTypeCode === serviceTypeCode);
    if (result) {
      return result;
    }
    this._isBusy = true;
    try {
      const response = await fetch(this._apiUrl + this.extraTypesUrl + '/' + serviceTypeCode);
      if (response) {
        result = await response.json();
      }
      this._isBusy = false;
      return result;
    } catch (e) {
      this._isBusy = false;
      throw ApiErrorResponseFromJSON(e);
    }
  }

  async fetchExtraTypes(): Promise<CmsExtraType[]> {
    this._isBusy = true;
    try {
      const response = await fetch(this._apiUrl + this.extraTypesUrl);
      if (response) {
        this._extraTypes = await response.json();
      }
      this._isBusy = false;
      return this._extraTypes;
    } catch (e) {
      this._extraTypes = [];
      this._isBusy = false;
      throw ApiErrorResponseFromJSON(e);
    }
  }

  async fetchOffers(): Promise<CmsOffer[] | undefined> {
    this._isBusy = true;
    try {
      const response = await fetch(this._apiUrl + this.offersUrl);
      if (response && response.status !== 204) {
        this._offers = await response.json();
      }
      this._isBusy = false;
      return this._offers;
    } catch (e) {
      this._offers = [];
      this._isBusy = false;
      throw ApiErrorResponseFromJSON(e);
    }
  }

  async fetchInformationDefinitions(code: string): Promise<CMSInformationDefinition[] | undefined> {
    this._isBusy = true;
    try {
      const requestOptions = {
        method: 'GET',
        headers: { 'X-IBE-Authorization': this._token }
      };

      const response = await this.fetchLocalized(
        `${this._apiUrl + this.informationDefinitionsUrl}/${code}`,
        requestOptions
      );

      if (response) {
        this._informationDefinitions = await response.json();
      }
      this._isBusy = false;
      return this._informationDefinitions;
    } catch (e) {
      this._informationDefinitions = [];
      this._isBusy = false;
      throw ApiErrorResponseFromJSON(e);
    }
  }

  async fetchFlexBoxes(code: string): Promise<CmsFlexBox[] | undefined> {
    this._isBusy = true;
    try {
      const requestOptions = {
        method: 'GET',
        headers: { 'X-IBE-Authorization': this._token }
      };

      const response = await fetch(`${this._apiUrl + this.flexBoxesUrl}/${code}`, requestOptions);

      if (response) {
        this._flexBoxes = await response.json();
      }
      this._isBusy = false;
      return this._flexBoxes;
    } catch (e) {
      this._flexBoxes = [];
      this._isBusy = false;
      throw ApiErrorResponseFromJSON(e);
    }
  }
}

export default CmsService;
