class FeatureConfiguration {
  private _rebookingAvailable: boolean;

  private _additionalNightAvailable: boolean;

  private _bookingCancellationAvailable: boolean;

  private _cancellationIntoVoucherAvailable: boolean;

  private _additionalNightDaysBeforeArrival: number;

  private _addExtrasDaysBeforeArrival: number;

  private _showBookingOverview: boolean;

  private _showParticipants: boolean;

  private _showBookedServices: boolean;

  private _showAddresses: boolean;

  private _cancellationText: string;

  get cancellationText(): string {
    return this._cancellationText;
  }

  set cancellationText(value: string) {
    this._cancellationText = value;
  }

  get rebookingAvailable(): boolean {
    return this._rebookingAvailable;
  }

  set rebookingAvailable(value: boolean) {
    this._rebookingAvailable = value;
  }

  get additionalNightAvailable(): boolean {
    return this._additionalNightAvailable;
  }

  set additionalNightAvailable(value: boolean) {
    this._additionalNightAvailable = value;
  }

  get bookingCancellationAvailable(): boolean {
    return this._bookingCancellationAvailable;
  }

  set bookingCancellationAvailable(value: boolean) {
    this._bookingCancellationAvailable = value;
  }

  get additionalNightDaysBeforeArrival(): number {
    return this._additionalNightDaysBeforeArrival;
  }

  set additionalNightDaysBeforeArrival(value: number) {
    this._additionalNightDaysBeforeArrival = value;
  }

  get cancellationIntoVoucherAvailable(): boolean {
    return this._cancellationIntoVoucherAvailable;
  }

  set cancellationIntoVoucherAvailable(value: boolean) {
    this._cancellationIntoVoucherAvailable = value;
  }

  get addExtrasDaysBeforeArrival(): number {
    return this._addExtrasDaysBeforeArrival;
  }

  set addExtrasDaysBeforeArrival(value: number) {
    this._addExtrasDaysBeforeArrival = value;
  }

  get showBookingOverview(): boolean {
    return this._showBookingOverview;
  }

  set showBookingOverview(value: boolean) {
    this._showBookingOverview = value;
  }

  get showParticipants(): boolean {
    return this._showParticipants;
  }

  set showParticipants(value: boolean) {
    this._showParticipants = value;
  }

  get showBookedServices(): boolean {
    return this._showBookedServices;
  }

  set showBookedServices(value: boolean) {
    this._showBookedServices = value;
  }

  get showAddresses(): boolean {
    return this._showAddresses;
  }

  set showAddresses(value: boolean) {
    this._showAddresses = value;
  }
}

export default FeatureConfiguration;
