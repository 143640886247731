import { useLocation } from 'react-router-dom';

function useExtendedQuery(): {
  query: URLSearchParams;
  param: (name: string) => string | null;
  buildParam: (param: string, value: string) => string;
  section: {
    get: () => string | null;
    build: (value: string) => string;
  };
} {
  const SectionQueryParamName = 'section';
  const query = new URLSearchParams(useLocation().search);

  const param = (name: string): string | null => {
    return query.get(name);
  };

  const buildParam = (name: string, value: string): string => {
    return `?${name}=${value}`;
  };

  return {
    query,
    param,
    buildParam,
    section: {
      get: (): string | null => {
        return param(SectionQueryParamName);
      },
      build: (value: string): string => {
        return buildParam(SectionQueryParamName, value);
      }
    }
  };
}

export default useExtendedQuery;
