import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { manageBookingPages, PageUrl } from '../../pages';

import { useAuth, useTranslation } from '@ibe/components';
import fallback from '../../Translations/generated/manage-booking-link.en.json';
import Keys from '../../Translations/generated/manage-booking-link.en.json.keys';
import { useLocation, useMount } from 'react-use';
import useCms from '../../Hooks/useCms';
import { animateScroll } from 'react-scroll';
import { QueryUtils } from '../../Util/QueryUtils';
import useQuery from '../../Util/useQuery';
import { Button } from 'reactstrap';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

const ManageBookingLink = ({ displayDivider }: { displayDivider?: boolean }) => {
  const { t } = useTranslation('manage-booking-link', fallback);
  const history = useHistory();
  const location = useLocation();
  const cms = useCms();
  const { authState } = useAuth();
  const query = useQuery();
  const isAddExtraWorkflow = QueryUtils.getIsAddExtraWorkflow(query);
  const [displayMyLogin, setDisplayMyLogin] = useState<boolean>(false);

  useMount(() => {
    if (location.pathname && manageBookingPages.includes(location.pathname as PageUrl)) {
      setDisplayMyLogin(false);
    } else {
      cms.fetchMyLoginActivation().then((value: boolean) => {
        setDisplayMyLogin(value);
      });
    }
  });

  function handleClick(): void {
    if (authState.token) {
      history.push(PageUrl.DASHBOARD);
    } else {
      history.push(PageUrl.LOGIN);
    }
  }

  return (
    <>
      {displayMyLogin && (
        <>
          {isAddExtraWorkflow ? (
            <Button
              color="primary"
              className="backToDashboard"
              onClick={() => {
                animateScroll.scrollToTop();
                handleClick();
              }}
            >
              <Icon icon={faArrowLeft} />
              {t(Keys.backToDashboard)}
            </Button>
          ) : (
            <div
              className="d-flex my-login"
              onClick={() => {
                animateScroll.scrollToTop();
                handleClick();
              }}
            >
              {t(Keys.ManageMyBooking)}
            </div>
          )}
          {displayDivider && <div className={'divider'} />}
        </>
      )}
    </>
  );
};

export default ManageBookingLink;
