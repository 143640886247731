import React, { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { routeChangeEvent, SessionStoragePersistence } from '@ibe/services';
import { useExternalSearchParams } from './Config/ExternalParamsContext';
import { Action, Location } from 'history';

type indexableWindow = Window &
  typeof globalThis & { parentIFrame: { scrollTo: (x: number, y: number) => void } };

class LocationProps {
  pathname = '';

  search = '';
}

const persist = new SessionStoragePersistence<LocationProps>(LocationProps);

const RouteBroadcast = ({ mode = 'DEFAULT' }: { mode?: 'DEFAULT' | 'WIDGET' }): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const context = useExternalSearchParams();
  const { pathname, search } = location;
  const isMounted = useRef<boolean>(false);

  useEffect(() => {
    return history.listen((currentLocation: Omit<Location, 'key'>) => {
      if (history.action === Action.Push) {
        const urlObject = new URL(
          `${currentLocation.pathname}${currentLocation.search}`,
          window.location.origin
        );
        if (!urlObject.searchParams.get('fromOnPopstate')) {
          window.history.pushState({ url: `${urlObject.pathname}${urlObject.search}` }, '');
        }
      }
    });
  }, []);

  useEffect(() => {
    const callback = (evt: PopStateEvent) => {
      const newUrl = evt?.state?.url;
      if (newUrl) {
        const urlObject = new URL(newUrl, window.location.origin);
        urlObject.searchParams.append('fromOnPopstate', 'true');
        history.push(`${urlObject.pathname}${urlObject.search}`);
      }
    };

    window.addEventListener('popstate', callback);

    return () => {
      window.removeEventListener('popstate', callback);
    };
  }, []);

  useEffect(() => {
    routeChangeEvent.broadcast(location);

    if (mode === 'WIDGET') {
      persist.useKey(`${context?.widgetSessionKeyPrefix || ''}iso__memory-router__location`);
      if (isMounted.current) {
        persist.set({ pathname, search });
      } else {
        isMounted.current = true;
        if (persist.exists()) {
          const { pathname: persistedPathname, search: persistedSearch } = persist.get();
          history.push(`${persistedPathname}${persistedSearch}`);
        }
      }
    }

    if ('parentIFrame' in window) {
      (window as indexableWindow).parentIFrame.scrollTo(0, 0);
      // It's the way how to use scrollTo from iframe-resizer used for meg project.
      // https://github.com/davidjbradshaw/iframe-resizer/blob/master/docs/iframed_page/methods.md
    }
  }, [pathname, search]);

  return <></>;
};

export default RouteBroadcast;
