import React, { ReactNode } from 'react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  message: string | ReactNode;
}

const MoveTicketNotification = (props: Props) => {
  const { message } = props;

  return (
    <Row>
      <Col className="col-auto mx-lg-0 mx-1 p-0">
        <FontAwesomeIcon icon={faInfoCircle} className="moveTicketNotification-icon" />
      </Col>
      <Col className="mx-1 p-0 moveTicketNotification-message">{message}</Col>
    </Row>
  );
};

export default MoveTicketNotification;
