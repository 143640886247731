import React, { RefObject, useEffect, useRef, useState } from 'react';
import { useMount } from 'react-use';
import { Alert, Button, Col, Row } from 'reactstrap';
import dayjs from 'dayjs';
import i18next from 'i18next';
import minMax from 'dayjs/plugin/minMax';
import { ApiHotel, ApiRoomContainer } from '@ibe/api';
import {
  DatePickerRef,
  DateRangeInput,
  HighlightDatesInformation,
  LoadingOverlay,
  PaxRoomSelection,
  ServiceSearchParams,
  useConfig,
  useTranslation
} from '@ibe/components';
import fallback from '../../Translations/generated/service-search.en.json';
import Keys from '../../Translations/generated/service-search.en.json.keys';
import ServiceSearchProps from './ServiceSearchProps';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendar,
  faCheckSquare,
  faEdit,
  faPercentage,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import { useOnClickOutside } from '../../Util/useOnClickOutside';
import MoveTicketNotification from '../MoveTicketNotification/MoveTicketNotification';
import CmsOffer from '../../Models/CMS/CmsOffer';
import useCms from '../../Hooks/useCms';
import utc from 'dayjs/plugin/utc';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(minMax);
dayjs.extend(utc);
dayjs.extend(isBetween);

const INVALID_DATE_STRING = 'Invalid Date';

export const MegServiceSearch = (
  props: Omit<ServiceSearchProps, 'datePickerRef'> & {
    datePickerRef?: RefObject<DatePickerRef>;
    accommodationBar?: JSX.Element;
    hotelModel?: ApiHotel;
    withRoomCountSelector?: boolean;
    minDateRangeSpan?: number;
    widgetView?: boolean;
    position?: 'TOP' | 'BOTTOM';
  }
): JSX.Element => {
  const {
    searchParams,
    maxDateRangeSpan,
    maxRooms,
    onSubmit,
    maxAdults,
    seasons,
    maxChildren,
    maxChildAge,
    datePickerRef,
    moveTicketMessage,
    accommodationBar,
    hotelModel,
    withRoomCountSelector,
    minDateRangeSpan,
    widgetView,
    position
  } = props;

  const { t } = useTranslation('service-search', fallback);
  const config = useConfig();
  const cms = useCms();
  const format =
    config.displayFormatDate[i18next.language || localStorage.getItem('i18nextLng') || ''] ||
    config.displayFormatDate[config.defaultLanguage];
  const imperativeHandleRef = useRef<{
    getTitle: () => string;
    toggleDropdown: () => void;
    getCurrentPax: () => ApiRoomContainer[];
    validateChildrenSelection: () => boolean;
  }>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [offers, setOffers] = useState<CmsOffer[]>([]);
  const popoverRef = useRef<HTMLDivElement>(null);
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  const [datePickerOpenConfirm, setDatePickerOpenConfirm] = useState<boolean>(false);
  const [paxSelection, setPaxSelection] = useState<ApiRoomContainer[]>(
    searchParams?.occupancy || []
  );
  const [dateRange, setDateRange] = useState<[string | undefined, string | undefined]>([
    undefined,
    undefined
  ]);
  const [paxDropdownOpen, setPaxDropdownOpen] = useState<boolean>(false);
  const paxDropdownOpenRef = useRef<boolean>(false);
  const dateEditRef = useRef<HTMLElement>(null);
  const dateRangeInputRef = useRef<{
    togglePopover: () => void;
    openPopover: () => void;
    closePopover: () => [startDate?: string, endDate?: string];
    getCurrentDateRange: () => [startDate?: string, endDate?: string];
  }>(null);
  const occupancyFromProps = searchParams?.occupancy;
  const startDateFromProps = searchParams?.startDate;
  const endDateFromProps = searchParams?.endDate;

  const packageCodeFromProps = searchParams?.code;

  useOnClickOutside([dateEditRef, popoverRef], (): void => {
    if (!datePickerOpen) {
      setDatePickerOpenConfirm(false);
    }
  });

  useMount(() => {
    setIsLoading(true);
    if (cms.hasOffers) {
      setOffers(cms.offers);
      setIsLoading(false);
    } else {
      cms
        .fetchOffers()
        .then(value => {
          setOffers(value || []);
        })
        .finally(() => setIsLoading(false));
    }
  });

  useEffect(() => {
    if (!!occupancyFromProps) {
      setPaxSelection([...occupancyFromProps]);
    }
    let newDateRange: [string | undefined, string | undefined] = [undefined, undefined];
    if (!!startDateFromProps && startDateFromProps !== INVALID_DATE_STRING) {
      newDateRange = [startDateFromProps, newDateRange[1]];
    }
    if (!!endDateFromProps && endDateFromProps !== INVALID_DATE_STRING) {
      newDateRange = [newDateRange[0], endDateFromProps];
    }
    setDateRange(newDateRange);
  }, [occupancyFromProps, startDateFromProps, endDateFromProps]);

  useEffect(() => {
    setTimeout(() => {
      if (!paxDropdownOpen) {
        paxDropdownOpenRef.current = false;
      }
    }, 0);
  }, [paxDropdownOpen]);

  const hasSelectedPax = (): boolean => {
    return !!paxSelection.find(
      selection => !!selection.adults || !!selection.children || !!selection.infants
    );
  };

  const getNumberOfAdults = (): number => {
    return (
      paxSelection?.reduce((total: number, current: ApiRoomContainer) => {
        return total + (current.adults || 0);
      }, 0) || 0
    );
  };

  const getNumberOfChildren = (): number => {
    return (
      paxSelection?.reduce((total: number, current: ApiRoomContainer) => {
        return total + (current.children || 0) + (current.infants || 0);
      }, 0) || 0
    );
  };

  const hasDateRange = (): boolean => {
    return (
      !!dateRange &&
      !!dateRange[0] &&
      dateRange[0] !== INVALID_DATE_STRING &&
      !!dateRange[1] &&
      dateRange[1] !== INVALID_DATE_STRING
    );
  };

  const isDateRangeValid = (
    startDate: string | undefined,
    endDate: string | undefined
  ): boolean => {
    if (
      !startDate ||
      !endDate ||
      (!!startDate && startDate === INVALID_DATE_STRING) ||
      (!!endDate && endDate === INVALID_DATE_STRING)
    )
      return false;

    const dayjsStart = dayjs(startDate, config.formatDate);
    const dayjsEnd = dayjs(endDate, config.formatDate);

    if (!dayjsStart.isValid() || !dayjsEnd.isValid()) {
      return false;
    }
    if (dayjsStart.isAfter(dayjsEnd)) {
      return false;
    }
    if (dayjsStart.isSame(dayjsEnd) && minDateRangeSpan !== 0) {
      return false;
    }
    const now = dayjs().startOf('day');
    return dayjsStart.isSame(now) || dayjsStart.isAfter(now);
  };

  const handleSubmit = (values: Partial<ServiceSearchParams>): void => {
    if (onSubmit) {
      onSubmit({
        region: values.region,
        startDate: values.startDate,
        endDate: values.endDate,
        occupancy: values.occupancy || [],
        metaData: values.metaData!,
        unitTypeCode: values.unitTypeCode!
      });
    }
  };

  const minDay = new Date();
  minDay.setDate(minDay.getDate() + config.datePickerMinDateOffset);

  const toggleOverlay = () => {
    if (!paxDropdownOpenRef.current) {
      imperativeHandleRef?.current?.toggleDropdown();
      paxDropdownOpenRef.current = true;
    } else {
      paxDropdownOpenRef.current = false;
      const newPax = imperativeHandleRef?.current?.getCurrentPax();
      if (!!newPax && imperativeHandleRef?.current?.validateChildrenSelection()) {
        setPaxSelection(newPax);
        if (
          hasDateRange() &&
          isDateRangeValid(dateRange[0], dateRange[1]) &&
          !!searchParams &&
          !widgetView
        ) {
          handleSubmit({
            ...searchParams,
            occupancy: newPax,
            startDate: dateRange[0],
            endDate: dateRange[1]
          });
        }
      }
    }
  };

  const handlePaxDropdownOpenChange = (dropdownOpen: boolean): void => {
    setPaxDropdownOpen(dropdownOpen);
  };

  const setDayAsAttribute = (): void => {
    setTimeout(() => {
      const element = document.querySelector(
        '.iso__service-search__meg .react-datepicker__day--range-start + .react-datepicker__day--range-end.react-datepicker__day--in-range'
      );
      if (!!element) {
        element.setAttribute('day', element.textContent || '');
      }
    }, 0);
  };

  const toggleDatePicker = (): void => {
    let newDateRange: [startDate?: string, endDate?: string] | undefined = undefined;
    if (!!dateRangeInputRef.current) {
      if (datePickerOpen) {
        dateRangeInputRef.current.closePopover();
        setDatePickerOpen(false);
      } else {
        if (datePickerOpenConfirm) {
          setDatePickerOpenConfirm(false);
          newDateRange = dateRangeInputRef.current.getCurrentDateRange();
        }
        if (!datePickerOpenConfirm) {
          dateRangeInputRef.current.openPopover();
          setDatePickerOpen(true);
          setDatePickerOpenConfirm(true);
          setDayAsAttribute();
        }
      }
    }
    if (!!newDateRange) {
      handleDateSubmit(newDateRange[0], newDateRange[1], true);
    }
  };

  const handleCalendarClose = (): void => {
    setDatePickerOpen(false);
  };

  const updateDateValues = (
    startDate?: string,
    endDate?: string,
    ignoreClose?: boolean
  ): [string | undefined, string | undefined] => {
    let value = [...dateRange] as [string | undefined, string | undefined];
    if (!!startDate) {
      value = [dayjs(startDate).format(config.formatDate), value[1]];
    }
    if (!!endDate) {
      value = [value[0], dayjs(endDate).format(config.formatDate)];
    }
    setDateRange(value);
    if (!!dateRangeInputRef.current && !ignoreClose) {
      dateRangeInputRef.current.closePopover();
      setDatePickerOpen(false);
      setDatePickerOpenConfirm(false);
    }
    return [value[0], value[1]];
  };

  const handleWidgetCalendarClose = (startDate?: string, endDate?: string): void => {
    updateDateValues(startDate, endDate);
    setDatePickerOpen(false);
  };

  const handleDateSubmit = (startDate?: string, endDate?: string, ignoreClose?: boolean): void => {
    const [start, end] = updateDateValues(startDate, endDate, ignoreClose);
    if (hasSelectedPax() && isDateRangeValid(start, end) && !!searchParams) {
      handleSubmit({
        ...searchParams,
        occupancy: [...paxSelection],
        startDate: start,
        endDate: end
      });
    }
  };

  const handlePaxChange = (pax: ApiRoomContainer[]): void => {
    setPaxSelection([...pax]);
    if (!!pax) {
      if (
        hasDateRange() &&
        isDateRangeValid(dateRange[0], dateRange[1]) &&
        !!searchParams &&
        !widgetView
      ) {
        handleSubmit({
          ...searchParams,
          occupancy: [...pax],
          startDate: dateRange[0],
          endDate: dateRange[1]
        });
      }
    }
  };

  function getHighlightDatesInformation(): HighlightDatesInformation | undefined {
    if (!offers.length) {
      return undefined;
    }

    return {
      dateRanges: offers
        .filter(offer => offer.specialOfferPackages)
        .filter(offer =>
          packageCodeFromProps ? offer.specialOfferPackages?.includes(packageCodeFromProps) : true
        )
        .filter(offer => offer.startDate && offer.endDate)
        .map(offer => {
          const offsetDate = dayjs(Date.now()).local().add(offer.offsetDays, 'day').startOf('day');
          const offerStart = dayjs(offer.startDate?.substring(0, offer.startDate.indexOf('T')))
            .local()
            .startOf('day');
          const offerEnd = dayjs(offer.endDate?.substring(0, offer.endDate.indexOf('T')))
            .local()
            .startOf('day');
          const offsetDateString: string = offsetDate.format();
          if (offsetDate.isBefore(offerStart)) {
            return {
              startDate: offerStart.format() as string,
              endDate: offerEnd.format() as string
            };
          } else if (offsetDate.isBefore(offerEnd)) {
            return {
              startDate: offsetDateString.substring(0, offsetDateString.indexOf('T')),
              endDate: offerEnd.format() as string
            };
          } else {
            return null;
          }
        })
        .filter(dr => dr?.startDate && dr?.endDate),
      highlightIcon: <FontAwesomeIcon icon={faPercentage} className="offer-highlight-icon-svg" />
    };
  }

  return (
    <LoadingOverlay isLoading={isLoading}>
      <div>
        <Row
          className={`iso__service-search iso__service-search__meg justify-content-lg-start justify-content-center ${
            widgetView && 'withButton'
          } ${position === 'BOTTOM' && 'upwards'}`}
        >
          <Col xs={12} lg={6} className="pb-1 iso__service-search__pax-section">
            <div className="iso__service-search__meg__edit" onClick={toggleOverlay}>
              <span>
                <FontAwesomeIcon icon={faUser} className="mr-2" />
                {!!imperativeHandleRef.current && hasSelectedPax()
                  ? `${paxSelection.length} ${t(Keys.room, { count: paxSelection.length })}${
                      getNumberOfAdults() > 0
                        ? `, ${getNumberOfAdults()} ${t(Keys.adult, {
                            count: getNumberOfAdults()
                          })}`
                        : ''
                    }${
                      getNumberOfChildren() > 0
                        ? `, ${getNumberOfChildren()} ${t(Keys.child, {
                            count: getNumberOfChildren()
                          })}`
                        : ''
                    }`
                  : t(Keys.pleaseSelect)}
              </span>
            </div>
            <PaxRoomSelection
              childAgePlaceholder={t(Keys.select)}
              searchPaxInputSearchable={false}
              initialOccupancy={[...paxSelection]}
              value={[...paxSelection]}
              onChange={handlePaxChange}
              withChildAgePicker
              withRoomCountSelector={withRoomCountSelector}
              withGroupRequestForm={false}
              maxRooms={maxRooms}
              minAdults={1}
              maxAdults={maxAdults}
              maxChildren={maxChildren}
              maxChildAge={maxChildAge}
              maxCapacity={100}
              imperativeHandleRef={imperativeHandleRef}
              rightAlignedMenu
              onDropdownOpenChange={(dropdownOpen: boolean) =>
                handlePaxDropdownOpenChange(dropdownOpen)
              }
              plusMinusMode
              fillWithValueOnOpen
              useInitialPersonAmounts
              customTitle={
                <span
                  className={`iso__service-search__meg__edit__toggle${
                    paxDropdownOpen ? ' iso__service-search__meg__edit__toggle--toggled' : ''
                  }`}
                >
                  <span>{paxDropdownOpen ? t(Keys.confirm) : t(Keys.edit)}</span>
                  <span>
                    <FontAwesomeIcon icon={paxDropdownOpen ? faCheckSquare : faEdit} />
                  </span>
                </span>
              }
              skipSearch={widgetView}
            />
          </Col>
          <Col xs={12} lg={6} className="pb-1 iso__service-search__date-section">
            <div className="iso__service-search__meg__edit" onClick={toggleDatePicker}>
              <span>
                <FontAwesomeIcon icon={faCalendar} className="mr-2" />
                {hasDateRange()
                  ? `${dayjs(dateRange[0]).format(format)} - ${dayjs(dateRange[1]).format(format)}`
                  : t(Keys.pleaseSelect)}
              </span>
              <span
                ref={dateEditRef}
                className={`iso__service-search__meg__edit__toggle${
                  datePickerOpen ? ' iso__service-search__meg__edit__toggle--toggled' : ''
                }`}
              >
                <span>{datePickerOpen ? t(Keys.confirm) : t(Keys.edit)}</span>
                <span>
                  <FontAwesomeIcon icon={datePickerOpen ? faCheckSquare : faEdit} />
                </span>
              </span>
            </div>
            <DateRangeInput
              dataTestId="serviceSearch_selectDate"
              startDate={dateRange[0]}
              endDate={dateRange[1]}
              minDate={minDay}
              minDateRangeSpan={minDateRangeSpan !== undefined ? minDateRangeSpan : 1}
              maxDateRangeSpan={maxDateRangeSpan}
              seasons={seasons}
              datePickerRef={datePickerRef}
              monthsShown={1}
              selectsDisabledDaysInRange
              customDivider={<span>-</span>}
              onCalendarClose={widgetView ? undefined : handleCalendarClose}
              onCalendarCloseDated={widgetView ? handleWidgetCalendarClose : undefined}
              popoverRef={popoverRef}
              popoverTargetRef={dateEditRef}
              dateRangeInputRef={dateRangeInputRef}
              onSubmit={widgetView ? undefined : handleDateSubmit}
              CustomHeader={
                <div className="iso__service-search__meg__date-range__custom">
                  {t(Keys.hotelStayDates)}
                </div>
              }
              stateFromPropsOnPopoverOpen
              useLocalSelectedDatesForInRangeCheck
              onYearChange={setDayAsAttribute}
              onMonthChange={setDayAsAttribute}
              onCalendarOpen={setDayAsAttribute}
              onChange={setDayAsAttribute}
              onPopoverToggle={setDayAsAttribute}
              withColorCodedAvailability={
                hotelModel?.metaInfo?.contentByMetaType?.AVAILABILITY_CALENDAR?.content
                  ?.isAvailabilityCalendarEnabled as boolean | undefined
              }
              highlightDatesInformation={getHighlightDatesInformation()}
              searchParams={searchParams}
            />
          </Col>
          {widgetView && (
            <Col className={'iso__service-search__submit'}>
              <Button
                color="primary"
                data-testid="serviceSearch_button_search"
                block
                type="submit"
                className="iso__service-search__button"
                onClick={() => handleDateSubmit()}
              >
                {t(Keys.submit)}
              </Button>
            </Col>
          )}
          {moveTicketMessage && (
            <Col sm={12}>
              <MoveTicketNotification message={moveTicketMessage} />
            </Col>
          )}
        </Row>
        {hasDateRange() && !isDateRangeValid(dateRange[0], dateRange[1]) && (
          <Alert className="mt-3" color="danger">
            {t(Keys.invalidInput)}
          </Alert>
        )}
        {accommodationBar}
      </div>
    </LoadingOverlay>
  );
};
