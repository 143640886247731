export enum QueryParamKey {
  UUID = 'uuid',
  SEARCH_START_DATE = 'start',
  SEARCH_END_DATE = 'end',
  WORKFLOW_CODE = 'wrk',
  PACKAGE_CODE = 'code',
  EXTRA_TYPE_CODE = 'serviceTypeCode',
  SALES_CHANNEL_CODE = 'salesChannelCode',
  DEFAULT_NUMBER_OF_ADULTS = 'adults',
  MAX_NUMBER_OF_ADULTS = 'maxAdults',
  MAX_NUMBER_OF_CHILDREN = 'maxChildren',
  MAX_AGE_OF_CHILDREN = 'maxChildAge',
  MAX_DURATION = 'maxDuration',
  PAYMENT_STATUS = 'sts',
  ITEM = 'item',
  BOOKING_ID = 'bookingId',
  OCCUPANCY = 'occupancy',
  CODE = 'code',
  REBOOKING_WORKFLOW = 'rebookingWorkflow',
  REBOOKING_ID = 'rebookingId',
  ADD_EXTRA_WORKFLOW = 'addExtraWorkflow',
  WORKFLOW_TYPE = 'wrkType',
  SHOW_HOTEL_DETAILS_POPUP = 'showHotelDetailsPopup'
}

export enum SessionStorageKeys {
  NAVIGATION = 'NAVIGATION',
  CONFERENCE_DATA = 'CONFERENCE_DATA'
}

export abstract class QueryUtils {
  static getUUID(query: URLSearchParams) {
    return query.get(QueryParamKey.UUID);
  }

  static getSearchStartDate(query: URLSearchParams) {
    return query.get(QueryParamKey.SEARCH_START_DATE);
  }

  static getSearchEndDate(query: URLSearchParams) {
    return query.get(QueryParamKey.SEARCH_END_DATE);
  }

  static getWorkflowCode(query: URLSearchParams) {
    return query.get(QueryParamKey.WORKFLOW_CODE);
  }

  static getPackageCode(query: URLSearchParams) {
    return query.get(QueryParamKey.PACKAGE_CODE);
  }

  static getExtraTypeCode(query: URLSearchParams) {
    return query.get(QueryParamKey.EXTRA_TYPE_CODE);
  }

  static getSalesChannelCode(query: URLSearchParams) {
    return query.get(QueryParamKey.SALES_CHANNEL_CODE);
  }

  static getDefaultNumberOfAdults(query: URLSearchParams) {
    return query.get(QueryParamKey.DEFAULT_NUMBER_OF_ADULTS);
  }

  static getMaxNumberOfAdults(query: URLSearchParams) {
    return query.get(QueryParamKey.MAX_NUMBER_OF_ADULTS);
  }

  static getMaxNumberOfChildren(query: URLSearchParams) {
    return query.get(QueryParamKey.MAX_NUMBER_OF_CHILDREN);
  }

  static getMaxAgeOfChildren(query: URLSearchParams) {
    return query.get(QueryParamKey.MAX_AGE_OF_CHILDREN);
  }

  static getMaxDuration(query: URLSearchParams) {
    return query.get(QueryParamKey.MAX_DURATION);
  }

  static getPaymentStatus(query: URLSearchParams) {
    return query.get(QueryParamKey.PAYMENT_STATUS);
  }

  static getItem(query: URLSearchParams) {
    return query.get(QueryParamKey.ITEM);
  }

  static getBookingId(query: URLSearchParams) {
    return query.get(QueryParamKey.BOOKING_ID);
  }

  static getOccupancy(query: URLSearchParams) {
    return query.get(QueryParamKey.OCCUPANCY);
  }

  static getCode(query: URLSearchParams) {
    return query.get(QueryParamKey.CODE);
  }

  static getIsRebookingWorkflow(query: URLSearchParams): boolean {
    return query.get(QueryParamKey.REBOOKING_WORKFLOW) === 'true';
  }

  static getIsAddExtraWorkflow(query: URLSearchParams): boolean {
    return query.get(QueryParamKey.ADD_EXTRA_WORKFLOW) === 'true';
  }

  static getWorkflowType(query: URLSearchParams) {
    return query.get(QueryParamKey.WORKFLOW_TYPE);
  }

  static showHotelDetailPopup(query: URLSearchParams): boolean {
    return query.get(QueryParamKey.SHOW_HOTEL_DETAILS_POPUP) === 'true';
  }

  //duplicated logic from Checkout and Extra Pages lifted up into one place

  static getParsedDefaultNumberOfAdults(query: URLSearchParams) {
    return QueryUtils.getDefaultNumberOfAdults(query)
      ? parseInt(QueryUtils.getDefaultNumberOfAdults(query) as string)
      : undefined;
  }

  static getParsedMaxAdults(query: URLSearchParams) {
    return QueryUtils.getMaxNumberOfAdults(query)
      ? parseInt(QueryUtils.getMaxNumberOfAdults(query) as string)
      : undefined;
  }

  static getParsedMaxChildren(query: URLSearchParams) {
    return QueryUtils.getMaxNumberOfChildren(query)
      ? parseInt(QueryUtils.getMaxNumberOfChildren(query) as string)
      : undefined;
  }

  static getParsedMaxChildAge(query: URLSearchParams) {
    return QueryUtils.getMaxAgeOfChildren(query)
      ? parseInt(QueryUtils.getMaxAgeOfChildren(query) as string)
      : undefined;
  }

  static getParsedMaxDuration(query: URLSearchParams) {
    return QueryUtils.getMaxDuration(query)
      ? parseInt(QueryUtils.getMaxDuration(query) as string)
      : undefined;
  }

  //---------------------------------------------------------------------
}
