import React from 'react';
import { InlineTabs, useTranslation } from '@ibe/components';
import Keys from '../../Translations/generated/workflow-tabs.en.json.keys';
import fallback from '../../Translations/generated/workflow-tabs.en.json';
import { extrasWorkflowPages, PageUrl, singleHotelBookingPages } from '../../pages';
import { QueryParamKey, QueryUtils } from '../../Util/QueryUtils';
import useQuery from '../../Util/useQuery';
import { useLocation, useHistory } from 'react-router-dom';
import useCms from '../../Hooks/useCms';

enum Workflow {
  PACKAGE = 0,
  HOTEL = 1,
  EXTRAS = 2
}

const WorkflowTabs = ({ hideIfOnlyOne }: { hideIfOnlyOne?: boolean }): JSX.Element => {
  const { t } = useTranslation('workflow-tabs', fallback);
  const history = useHistory();
  const query = useQuery();
  const cms = useCms();
  const location = useLocation();
  const cleanQuery = '?' + QueryParamKey.WORKFLOW_CODE + '=' + QueryUtils.getWorkflowCode(query);
  const packageUrl = PageUrl.PACKAGE + cleanQuery;
  const hotelWrkUrl =
    PageUrl.HOTEL_WORKFLOW +
    cleanQuery +
    '&' +
    QueryParamKey.SALES_CHANNEL_CODE +
    '=' +
    cms.findHotelWorkflow(cms.workflows)?.salesChannel;
  const extrasWrkUrl = PageUrl.EXTRAS_TYPE_LIST + cleanQuery;
  const availableRedirectionUrls: string[] = [];
  const availableTabsPositions = new Map<number, number>();

  function getActiveTab(): number {
    if (singleHotelBookingPages.includes(location.pathname as PageUrl)) {
      return availableTabsPositions.get(Workflow.HOTEL) || 0;
    } else if (extrasWorkflowPages.includes(location.pathname as PageUrl)) {
      return availableTabsPositions.get(Workflow.EXTRAS) || 0;
    } else {
      return 0;
    }
  }

  function getTabs(): Map<string | JSX.Element, JSX.Element> {
    const availableTabsMap = new Map();
    let indexCounter = 0;
    if (!!cms.findPackageWorkflow(cms.workflows)) {
      availableRedirectionUrls.push(packageUrl);
      availableTabsPositions.set(Workflow.PACKAGE, indexCounter++);
      availableTabsMap.set(t(Keys.hotelAndTickets), <></>);
    }
    if (!!cms.findHotelWorkflow(cms.workflows)) {
      availableRedirectionUrls.push(hotelWrkUrl);
      availableTabsPositions.set(Workflow.HOTEL, indexCounter++);
      availableTabsMap.set(t(Keys.hotel), <></>);
    }
    if (!!cms.findExtraWorkflow(cms.workflows)) {
      availableRedirectionUrls.push(extrasWrkUrl);
      availableTabsPositions.set(Workflow.EXTRAS, indexCounter++);
      availableTabsMap.set(t(Keys.products), <></>);
    }
    return availableTabsMap;
  }

  return (
    <>
      {!!getTabs() && (!hideIfOnlyOne || getTabs().size > 1) && (
        <InlineTabs
          className="workflow-tabs"
          items={getTabs()}
          onTabClick={tabId => {
            history.push(availableRedirectionUrls[tabId]);
          }}
          activeTab={getActiveTab()}
          tabIdentifier="workflow-tabs"
        />
      )}
    </>
  );
};

export default WorkflowTabs;
