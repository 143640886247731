import { LoggerFactory } from '@ibe/services';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    OneTrust?: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    OnetrustActiveGroups?: any;
  }
}

export enum CookieType {
  PERFORMANCE = 'C0002'
}

export default class Cookie {
  private static logger = LoggerFactory.get('Cookie');

  static register(consentChangeCallback: () => void): void {
    if (window.OneTrust) {
      window.OneTrust.OnConsentChanged(() => {
        this.logger.log('Cookie groups: ' + window.OnetrustActiveGroups);

        consentChangeCallback();
      });
    }
  }

  static isEnabled(): boolean {
    return window.OneTrust;
  }

  static hasType(type: CookieType): boolean {
    if (this.isEnabled()) {
      const groups = window.OnetrustActiveGroups.replace(/(^,)|(,$)/g, '').split(',');
      return groups.indexOf(type) !== -1;
    }

    return true;
  }
}
