import { ApiImage } from '@ibe/api';
import Accordion from '../Accordion';

class CmsInsurance {
  showInCheckoutPage: boolean;

  picture: ApiImage;

  shortDescription: string;

  longDescription: string;

  accordions: Accordion[];
}

export default CmsInsurance;
