import React from 'react';
import { Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins } from '@fortawesome/free-solid-svg-icons';
import { ReactSelectOption, Select, useAppService, useCurrency } from '@ibe/components';

export enum Currency {
  DKK = 'DKK',
  EUR = 'EUR',
  USD = 'USD',
  GBP = 'GBP',
  SEK = 'SEK',
  NOK = 'NOK'
}

const CurrencySelector = (): JSX.Element => {
  const { currencyCode, setCurrencyCode } = useCurrency();

  function getOptions(): ReactSelectOption[] {
    return [
      { value: Currency.DKK, label: Currency.DKK },
      { value: Currency.EUR, label: Currency.EUR },
      { value: Currency.GBP, label: Currency.GBP },
      { value: Currency.USD, label: Currency.USD },
      { value: Currency.SEK, label: Currency.SEK },
      { value: Currency.NOK, label: Currency.NOK }
    ];
  }

  function getLabel(option: ReactSelectOption): string {
    return option.label;
  }

  function getValue(option: ReactSelectOption): string {
    return option.value;
  }

  async function onCurrencyChange(option: ReactSelectOption): Promise<void> {
    await setCurrencyCode(option.value);
    window.location.reload();
  }

  return (
    <Row className="currency_selector-container align-items-center no-gutters">
      <Col>
        <FontAwesomeIcon size={'lg'} className="currency_selector-icon mr-1" icon={faCoins} />
      </Col>
      <Col>
        <Select
          selectedOption={{ value: currencyCode, label: currencyCode }}
          options={getOptions()}
          dataTestId="currency_selector"
          getLabel={option => getLabel(option)}
          getValue={option => getValue(option)}
          onChange={option => onCurrencyChange(option)}
          isSearchable={false}
        />
      </Col>
    </Row>
  );
};

export default CurrencySelector;
