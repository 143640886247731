import {
  loginEvent,
  logoutEvent,
  routeChangeEvent,
  TrackingEvent as T,
  RouteEvent,
  itemDetailEvent,
  cartViewedEvent,
  checkoutStartedEvent,
  checkoutFinishedEvent,
  itemListEvent,
  bookingItemAddedEvent
} from '@ibe/services';
import CustomTrackingEvent from './CustomTrackingEvent';
import { appService } from '@ibe/components';
import { hotjar } from 'react-hotjar';
import { tracking } from './setup';
import {
  packageListEvent,
  itemExtraComponentListEvent,
  insurancePopupAddedEvent,
  insuranceCheckoutAddedEvent,
  bookingItemRemovedEvent
} from './Events/Events';
import Cookie, { CookieType } from '../Cookie/Cookie';

packageListEvent.subscribe(payload =>
  tracking.track(CustomTrackingEvent.PACKAGE_LIST_VIEW, payload)
);

itemExtraComponentListEvent.subscribe(payload =>
  tracking.track(CustomTrackingEvent.PRODUCT_EXTRA_VIEW, payload)
);

checkoutStartedEvent.subscribe(payload =>
  tracking.track(CustomTrackingEvent.CART_CHECKOUT_START, payload)
);

checkoutFinishedEvent.subscribe(payload =>
  tracking.track(CustomTrackingEvent.CART_CHECKOUT_FINISH, payload)
);

itemListEvent.subscribe(payload => tracking.track(T.PRODUCT_LIST_VIEW, payload));

itemDetailEvent.subscribe(payload => tracking.track(T.PRODUCT_DETAIL_VIEW, payload));

insuranceCheckoutAddedEvent.subscribe(payload =>
  tracking.track(CustomTrackingEvent.INSURANCE_ADDED_CHECKOUT, payload)
);

insurancePopupAddedEvent.subscribe(payload =>
  tracking.track(CustomTrackingEvent.INSURANCE_ADDED_POPUP, payload)
);

cartViewedEvent.subscribe(payload => tracking.track(T.CART_VIEW, payload));

bookingItemRemovedEvent.subscribe(payload => tracking.track(T.CART_ITEM_REMOVED, payload));

bookingItemAddedEvent.subscribe(payload => tracking.track(T.CART_ITEM_ADDED, payload));

routeChangeEvent.subscribe((event: RouteEvent) => {
  tracking.page();

  const config = appService.config;
  if (
    config.tracking.hotjar.hjid &&
    config.tracking.hotjar.hjsv &&
    Cookie.hasType(CookieType.PERFORMANCE)
  ) {
    hotjar.stateChange(event.pathname);
  }
});

loginEvent.subscribe(state => {
  tracking.track(T.LOGIN).then(() => {});
  if (state.user && state.user.id) {
    tracking.identify(state.user.id, state).then(() => {});
  }
});

logoutEvent.subscribe(() => tracking.track(T.LOGOUT));
