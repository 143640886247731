import React from 'react';

import { PageLayout } from '../lib';

const NotFound = (): JSX.Element => (
  <PageLayout className={'p-3'}>
    <h1>404</h1>
    <h3>No matching route found.</h3>
  </PageLayout>
);

export default NotFound;
