import { ApiRoomContainer } from '@ibe/api';

export default class PackageModelId {
  code: string;

  typeCode: string;

  destinationCode: string;

  departureAirport: string;

  destinationAirport: string;

  startDate: string;

  endDate: string;

  roomContainer: ApiRoomContainer[];
}
