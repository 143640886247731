import { LocalStorageKey } from '../Storage/LocalStorageKeyRegistry';
import { useConfig } from '@ibe/components';
import { useLocalStorage, useSessionStorage } from 'react-use';

const useAuthSessionPersistence = <T>(key: LocalStorageKey) => {
  const config = useConfig();
  return config.sessionKeyTabsShared ? useLocalStorage<T>(key) : useSessionStorage<T>(key);
};

export default useAuthSessionPersistence;
