import { Theme } from '@ibe/components';
import { configService as config } from '../Config/config';
import { ApiRoomContainer } from '@ibe/api';

export async function getTheme(): Promise<Theme | {}> {
  let theme;
  if (config.get().theme === 'gardaland') {
    const favicon = document.getElementById('favicon');
    if (favicon) {
      (favicon as HTMLLinkElement).href = `${document.location.origin}/gardaland_favicon.ico`;
    }
    await import('../Theme/Styles/Gardaland/theme').then(gardalandTheme => {
      theme = gardalandTheme.default;
    });
  } else if (config.get().theme === 'heidepark') {
    const favicon = document.getElementById('favicon');
    if (favicon) {
      (favicon as HTMLLinkElement).href = `${document.location.origin}/heidepark_favicon.png`;
    }
    await import('../Theme/Styles/Heidepark/theme').then(heideparkTheme => {
      theme = heideparkTheme.default;
    });
  }
  // Thorpe Park
  else if (config.get().theme === 'thorpe') {
    const favicon = document.getElementById('favicon');
    if (favicon) {
      (favicon as HTMLLinkElement).href = `${document.location.origin}/thorpe_favicon.ico`;
    }
    await import('../Theme/Styles/Thorpe/theme').then(thorpeTheme => {
      theme = thorpeTheme.default;
    });
  }
  // Warwick Park
  else if (config.get().theme === 'wac') {
    const favicon = document.getElementById('favicon');
    if (favicon) {
      (favicon as HTMLLinkElement).href = `${document.location.origin}/warwick_favicon.ico`;
    }
    await import('../Theme/Styles/WarwickCastle/theme').then(warwickTheme => {
      theme = warwickTheme.default;
    });
  }
  // Alton
  else if (config.get().theme === 'alton') {
    const favicon = document.getElementById('favicon');
    if (favicon) {
      (favicon as HTMLLinkElement).href = `${document.location.origin}/alton_favicon.png`;
    }
    await import('../Theme/Styles/AltonTowers/theme').then(altonTheme => {
      theme = altonTheme.default;
    });
  }
  // Chessington Park
  else if (config.get().theme === 'chessington') {
    const favicon = document.getElementById('favicon');
    if (favicon) {
      (favicon as HTMLLinkElement).href = `${document.location.origin}/chessington_favicon.ico`;
    }
    await import('../Theme/Styles/ChessingtonWorldOfAdventure/theme').then(chessingtonTheme => {
      theme = chessingtonTheme.default;
    });
    //   Legoland Billund
  } else if (config.get().theme === 'billund') {
    const favicon = document.getElementById('favicon');
    if (favicon) {
      (favicon as HTMLLinkElement).href = `${document.location.origin}/billund_favicon.ico`;
    }
    // await import('../Theme/Styles/ChessingtonWorldOfAdventure/theme').then(chessingtonTheme => {
    //   theme = chessingtonTheme.default;
    // });
  } else {
    const favicon = document.getElementById('favicon');
    if (favicon) {
      (favicon as HTMLLinkElement).href = `${document.location.origin}/favicon.ico`;
    }
    await import('../Theme/Styles/Legoland/theme').then(legolandTheme => {
      theme = legolandTheme.default;
    });
  }

  if (theme) {
    return theme;
  }

  return {};
}

export const jumpToTop = (): void => {
  window.scroll(0, 0);
};

export const sumPaxes = (occupancy: ApiRoomContainer[]): number => {
  return occupancy.map(rc => rc.infants + rc.children + rc.adults).reduce((p, c) => p + c, 0);
};
