import { RefObject, useEffect } from 'react';

export function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  refs: RefObject<T>[],
  handler: (event: MouseEvent) => void,
  mouseEvent: 'mousedown' | 'mouseup' = 'mousedown'
): void {
  useEffect(() => {
    const eventListener = (event: MouseEvent) => {
      const els = refs.map(ref => ref?.current || undefined);

      if (!els.find(el => !!el) || els.some(el => el?.contains(event.target as Node))) {
        return;
      }

      handler(event);
    };

    window.addEventListener(mouseEvent, eventListener);

    return (): void => {
      window.removeEventListener(mouseEvent, eventListener);
    };
  }, []);
}
