import {
  ApiBookedItem,
  ApiBooking,
  ApiBookingState,
  ApiComponentType,
  ApiCustomer,
  ApiDocument,
  ApiHotel,
  ApiHotelRoom,
  ApiItem,
  ApiItemType,
  ApiPackageCart,
  ApiPackageModelDefinition,
  ApiPrice,
  ApiPriceModifierType,
  ApiRoomContainer,
  ApiRoomRate,
  ApiTraveler,
  ApiTravelerFromJSON,
  ApiTravelerType
} from '@ibe/api';
import { ApiService, clone, decodeObjectBase64, PriceFactory } from '@ibe/services';
import PackageDetailsIdKey from '../Models/PackageDetailsIdKey';
import PackageModelId from '../Models/PackageModelId';
import { DocumentItemProps } from '../Components/CollapseItem/CollapseItem';
import PriceUtil from '../Components/MMB/Util/PriceUtil';

class PackageKey {
  packageModelId: string;
}

class PackageModelIdKey {
  code: string;
}

const ADULT_NAME_PLACEHOLDER = 'Adult';
const CHILD_NAME_PLACEHOLDER = 'Child';
const INFANT_NAME_PLACEHOLDER = 'Infant';

abstract class BookingUtils {
  static findBookedItem(
    booking: ApiBooking | null | undefined,
    itemId: string
  ): ApiBookedItem | null | undefined {
    if (booking) {
      const item = booking.bookedItems.find(bookingItem => bookingItem.id === itemId);
      return item;
    }
    return null;
  }

  static findBookedItemsByType(
    booking: ApiBooking | null | undefined,
    itemType: ApiItemType
  ): ApiBookedItem[] | undefined {
    if (booking === null || booking === undefined) {
      return undefined;
    } else {
      return booking.bookedItems.filter(
        (bookedItem: ApiBookedItem) => itemType === bookedItem.itemType
      );
    }
  }

  static findRelatedItems(
    booking: ApiBooking | null | undefined,
    bookedItem: ApiBookedItem
  ): ApiItem[] {
    let result: ApiItem[] = [];
    let currentLevel = booking?.items.filter(item => item.id === bookedItem.idParent);
    while (currentLevel && currentLevel.length > 0) {
      result = result.concat(currentLevel);
      const parentIds = new Set(currentLevel.map(item => item.idParent));
      currentLevel = booking?.items.filter(item => parentIds.has(item.id));
    }

    return result;
  }

  static isHotelType(booking: ApiBooking | null | undefined, itemId: string): boolean {
    return ApiItemType.HOTEL === this.findBookedItem(booking, itemId)?.itemType;
  }

  static isInsuranceType(booking: ApiBooking | null | undefined, itemId: string): boolean {
    return ApiItemType.INSURANCE === this.findBookedItem(booking, itemId)?.itemType;
  }

  static isExtraType(booking: ApiBooking | null | undefined, itemId: string): boolean {
    return ApiItemType.EXTRA === this.findBookedItem(booking, itemId)?.itemType;
  }

  static getTravelersRates(roomRate: ApiRoomRate, fallback?: ApiRoomContainer): Array<ApiTraveler> {
    const travelers: Array<ApiTraveler> = [];

    for (let i = 0; i < (roomRate.paxInfo.adults || 0); i += 1) {
      travelers.push({
        type: ApiTravelerType.ADULT
      } as ApiTraveler);
    }

    for (let i = 0; i < (roomRate.paxInfo.children || 0); i += 1) {
      travelers.push({
        type: ApiTravelerType.CHILD,
        age: roomRate.paxInfo.childrenAges[i]
      } as ApiTraveler);
    }

    for (let i = 0; i < (roomRate.paxInfo.infants || 0); i += 1) {
      travelers.push({
        type: ApiTravelerType.INFANT,
        age: roomRate.paxInfo.infantsAges.at(i) || fallback?.infantsAges.at(i)
      } as ApiTraveler);
    }

    return travelers;
  }

  // static getRoomContainers(occupancy:  ApiRoomContainer[], treatInfantsAsChildren: boolean, maxInfantAge: number): ApiRoomContainer[] {
  //   // const { treatInfantsAsChildren, maxInfantAge } = this.config.traveler;
  //   const roomContainers = occupancy?.map(roomContainer => {
  //     const infants = roomContainer.childrenAges
  //         ? roomContainer.childrenAges.filter((age: number) => age <= maxInfantAge).length
  //         : 0;
  //     const childrenAges = roomContainer.childrenAges
  //         ? roomContainer.childrenAges.filter((age: number) => age > maxInfantAge)
  //         : [];
  //     const infantsAges =
  //         roomContainer.childrenAges && !treatInfantsAsChildren
  //             ? roomContainer.childrenAges.filter((age: number) => age <= maxInfantAge)
  //             : [];
  //
  //     const children = treatInfantsAsChildren
  //         ? roomContainer.childrenAges.filter((age: number) => age > maxInfantAge).length
  //         : childrenAges.length;
  //     const { adults } = roomContainer;
  //
  //     return ApiRoomContainerFromJSON({ adults, children, infants, childrenAges, infantsAges });
  //   });
  //
  //   return roomContainers;
  // }

  static getPackageFromBooking(booking: ApiBooking | null | undefined): ApiBookedItem | undefined {
    return booking?.bookedItems.find(bookedItem => bookedItem.itemType === ApiItemType.PACKAGE);
  }

  static getBookedPackageCode(booking: ApiBooking | null): string | null {
    if (booking === null) {
      return null;
    }
    const packageBookedItem = booking.bookedItems.find(item => {
      return item.itemType === ApiItemType.PACKAGE;
    });

    if (packageBookedItem) {
      const packageDetailsIdKey = decodeObjectBase64<PackageDetailsIdKey>(
        PackageDetailsIdKey,
        packageBookedItem.id
      );
      if (packageDetailsIdKey.packageModelId) {
        const packageModel = decodeObjectBase64<PackageModelId>(
          PackageModelId,
          packageDetailsIdKey.packageModelId
        );
        return packageModel.code;
      }
    }
    return null;
  }

  static getCartBooking(
    packageCart: ApiPackageCart | null,
    booking: ApiBooking | null,
    relatedExtraBookedItems: Map<ApiBookedItem, ApiBookedItem[]>,
    onSingleHotelWorkflow: boolean,
    isAddExtraWorkflow: boolean
  ) {
    if ((packageCart?.packageModel.packageDetails?.[0] || onSingleHotelWorkflow) && booking) {
      const result = clone(booking) as ApiBooking;
      const relatedItems: ApiBookedItem[] =
        Array.from(relatedExtraBookedItems.values()).flatMap(item => item) || [];

      result.bookedItems = result.bookedItems.filter(
        bookedItem =>
          bookedItem.componentType !== ApiComponentType.REQUIRED &&
          bookedItem.itemType !== ApiItemType.PACKAGE &&
          ((isAddExtraWorkflow && bookedItem.bookingState === ApiBookingState.OPEN) ||
            !isAddExtraWorkflow)
      );

      relatedItems.forEach(item => {
        const idx = result.bookedItems.findIndex(bookedItem => item.id === bookedItem.id);

        if (idx !== -1) {
          const foundItem = result.bookedItems[idx];
          if (foundItem.referenceId !== item.referenceId) {
            result.bookedItems.splice(idx, 1);
          }
        }
      });

      result.bookedItems.forEach(bookedItem => {
        bookedItem.relatedBookedItemId = undefined;
      });

      if (isAddExtraWorkflow) {
        result.price = result.openAmount;
        result.bookedItems.forEach(bookedItem => {
          result.price.modifiers = [...result.price.modifiers, ...bookedItem.price.modifiers];
        });
      }
      return result;
    }
  }

  static mapPrrlDocs = (
    api: ApiService,
    documents: Array<ApiDocument>,
    bookingId: string
  ): Array<DocumentItemProps> => {
    return documents.map(doc => {
      return {
        type: doc.type,
        label: doc.precontractualAttachmentId.toString(),
        onClick: () => {
          const link = window.document.createElement('a');
          link.download = doc.type;
          link.target = '_blank';
          link.rel = 'noopener noreferrer';

          api
            .getDocument(bookingId, doc.precontractualAttachmentId, doc.type)
            .then((result: Blob | MediaSource) => {
              link.href = URL.createObjectURL(result);
              link.click();

              URL.revokeObjectURL(link.href);
            });
        }
      };
    });
  };

  static createTravelersPlaceholderForOneRoomBooking(
    booking: ApiBooking | undefined | null,
    businessPartner: ApiCustomer,
    travelers: Array<ApiTraveler>
  ): Array<ApiTraveler> {
    let adultOrderNumber = 0;
    let childOrderNumber = 1;
    let infantOrderNumber = 1;

    return travelers.map((traveler, index) => {
      let orderNumberOfTraveler;
      let salutation;

      if (traveler.type === ApiTravelerType.ADULT) {
        orderNumberOfTraveler = adultOrderNumber;
        salutation = businessPartner.salutation;
        adultOrderNumber++;
      } else if (traveler.type === ApiTravelerType.CHILD) {
        orderNumberOfTraveler = childOrderNumber;
        salutation = 'CHD';
        childOrderNumber++;
      } else {
        orderNumberOfTraveler = infantOrderNumber;
        salutation = 'INF';
        infantOrderNumber++;
      }

      return this.buildMockupTraveler(
        booking,
        businessPartner,
        traveler,
        salutation,
        index,
        orderNumberOfTraveler
      );
    });
  }

  static createTravelersPlaceholderForMultiRoomBooking(
    booking: ApiBooking | undefined | null,
    businessPartner: ApiCustomer
  ): Array<ApiTraveler> {
    const bookedHotels = BookingUtils.findBookedItemsByType(booking, ApiItemType.HOTEL) || [];

    if (booking && bookedHotels.length > 1) {
      const mappedTravelers = bookedHotels.map((hotel, index) => {
        const personsIds: String[] = [];

        let adultOrderNumber = 0;
        let childOrderNumber = 1;
        let infantOrderNumber = 1;

        Object.keys(hotel.priceByPersonId).forEach(item => personsIds.push(item));
        const relatedTravelers = booking.travelers.filter(traveler =>
          personsIds.includes(traveler.id)
        );

        return relatedTravelers.map((traveler, idx) => {
          let orderNumberOfTraveler;
          let salutation;

          if (traveler.type === ApiTravelerType.ADULT) {
            orderNumberOfTraveler = adultOrderNumber;
            salutation = businessPartner.salutation;
            adultOrderNumber++;
          } else if (traveler.type === ApiTravelerType.CHILD) {
            orderNumberOfTraveler = childOrderNumber;
            salutation = 'CHD';
            childOrderNumber++;
          } else {
            orderNumberOfTraveler = infantOrderNumber;
            salutation = 'INF';
            infantOrderNumber++;
          }

          return this.buildMockupTraveler(
            booking,
            businessPartner,
            traveler,
            salutation,
            idx,
            orderNumberOfTraveler
          );
        });
      });

      return mappedTravelers.flat();
    }

    return [];
  }

  static getAllCharacteristics(
    booking?: ApiBooking | null,
    packageModelDefinition?: ApiPackageModelDefinition | null
  ) {
    let hotel: ApiHotel | undefined;

    if (booking?.rebookingId) {
      hotel = booking?.items.find(b => b.itemType === ApiItemType.HOTEL) as ApiHotel;
    } else {
      const bookedHotel = booking?.bookedItems.find(b => b.itemType === ApiItemType.HOTEL);
      const roomRate = booking?.items.find(it => bookedHotel?.idParent === it.id) as ApiRoomRate;
      const room = booking?.items.find(it => roomRate?.idParent === it.id) as ApiHotelRoom;
      hotel = booking?.items.find(it => room?.idParent === it.id) as ApiHotel;
    }

    return [...(hotel?.characteristics || []), ...(packageModelDefinition?.characteristics || [])];
  }

  private static getTravelerNamePlaceholder(traveler: ApiTraveler, index: number): string {
    let namePlaceholder = '';

    if (traveler.type === ApiTravelerType.ADULT) {
      namePlaceholder = ADULT_NAME_PLACEHOLDER;
    } else if (traveler.type === ApiTravelerType.CHILD) {
      namePlaceholder = CHILD_NAME_PLACEHOLDER;
    } else {
      namePlaceholder = INFANT_NAME_PLACEHOLDER;
    }
    return namePlaceholder + index;
  }

  private static buildMockupTraveler(
    booking: ApiBooking | undefined | null,
    businessPartner: ApiCustomer,
    traveler: ApiTraveler,
    salutation: string,
    index: number,
    orderNumber: number
  ): ApiTraveler {
    const age = booking?.travelers.find(
      trav => trav.type !== ApiTravelerType.ADULT && trav.id === traveler.id
    )?.age;

    if (index === 0) {
      return ApiTravelerFromJSON({
        id: traveler.id,
        salutation: salutation,
        firstName: businessPartner.firstName,
        lastName: businessPartner.lastName,
        age: age,
        type: traveler.type
      });
    }
    return ApiTravelerFromJSON({
      id: traveler.id,
      salutation: salutation,
      firstName: this.getTravelerNamePlaceholder(traveler, orderNumber),
      lastName: businessPartner.lastName || '',
      age: age,
      type: traveler.type
    });
  }

  public static adaptRoomContainer = (originRoomContainer: ApiRoomContainer): ApiRoomContainer => {
    const results: ApiRoomContainer = {
      adults: 0,
      children: 0,
      childrenAges: [],
      infants: 0,
      infantsAges: []
    };
    results.adults = originRoomContainer.adults;
    results.children = originRoomContainer.children + originRoomContainer.infants;
    results.childrenAges = originRoomContainer.childrenAges.concat(
      ...originRoomContainer.infantsAges
    );
    results.infants = 0;
    results.infantsAges = [];
    results.travelersIds = originRoomContainer.travelersIds;

    return results;
  };

  public static getTravelerLabel(
    traveler: ApiTraveler,
    adultLabel: string,
    childLabel: string,
    infantLabel: string,
    idx: number,
    ageLabel: string
  ): string {
    switch (traveler.type) {
      case ApiTravelerType.CHILD:
        return `${childLabel} ${idx}${ageLabel ? ` ${ageLabel} ${traveler.age}` : ''}`;
      case ApiTravelerType.INFANT:
        return `${infantLabel} ${idx}${ageLabel ? ` ${ageLabel} ${traveler.age}` : ''}`;
      default:
        return `${adultLabel} ${idx}`;
    }
  }

  public static getTravelersPerRoom(
    bookedHotelsOccupancy: ApiRoomContainer[],
    travelers: ApiTraveler[]
  ): ApiTraveler[][] | null {
    const travelersPerRoom: ApiTraveler[][] = [];
    bookedHotelsOccupancy.forEach(roomContainer => {
      const roomTravelers: ApiTraveler[] = [];
      roomContainer.travelersIds?.forEach(id => {
        const travelerFound = travelers.find(traveler => traveler.id === id);
        if (travelerFound) {
          roomTravelers.push(travelerFound);
        }
      });
      travelersPerRoom.push(roomTravelers);
    });
    return travelersPerRoom[0].length > 0 ? travelersPerRoom : null;
  }

  public static getBookingFinalPrice(
    booking: ApiBooking | undefined,
    isRebookingWorkflow: boolean,
    isAddExtraWorkflow: boolean
  ): ApiPrice | null {
    if (booking) {
      const priceDiscounts = PriceFactory.create(0, booking?.price.currencyCode);

      booking.price.modifiers
        .filter(m => m.type === ApiPriceModifierType.DISCOUNT)
        .forEach(modifier => {
          priceDiscounts.finalPrice -= modifier.absolute;
        });

      if (booking.rebookingId && isRebookingWorkflow) {
        return PriceFactory.create(
          booking.openAmount.finalPrice + priceDiscounts.finalPrice,
          booking.price.currencyCode
        );
      }

      if (isAddExtraWorkflow) {
        const priceOfOnlyNewItems =
          booking?.changedAmount || PriceUtil.getPriceOfOnlyNewItems(booking);

        return PriceFactory.create(
          priceOfOnlyNewItems.finalPrice + priceDiscounts.finalPrice,
          booking.price.currencyCode
        );
      }

      return PriceFactory.create(
        booking.price.finalPrice + priceDiscounts.finalPrice,
        booking.price.currencyCode
      );
    }

    return null;
  }
}

export default BookingUtils;
